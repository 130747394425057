<template>
  <v-card>
    <v-card-title>
      <span class="headline">Proyectos de Investigación</span>
    </v-card-title>
    <v-card-text>
      <FilterQuery
        v-if="path"
        id="filterQuery"
        :filters="filters"
        :field-filters="fieldFilters"
        @freshFilters="initialize"
      />
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loading"
        item-key="id"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :show-select="path"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-tooltip
              v-if="!path"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-2 my-2"
                  small
                  fab
                  dark
                  color="primary"
                  v-on="on"
                  @click.stop="dialog = true"
                >
                  <v-icon dark>
                    fa-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <template v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2 my-2"
                    small
                    text
                    color="green"
                    v-on="on"
                    @click.stop="getExcel"
                  >
                    <v-icon dark>
                      fa-file-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Excel</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2 my-2"
                    small
                    text
                    color="primary"
                    v-on="on"
                    @click.stop="getAnexos"
                  >
                    <v-icon dark>
                      fa-file-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Anexos</span>
              </v-tooltip>
              <v-menu
                offset-y
                origin="center center"
                class="elevation-1"
                :nudge-bottom="14"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        class="mx-2 my-2"
                        small
                        text
                        color="primary"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon dark>
                          fa-file-word
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar Documentos</span>
                  </v-tooltip>
                </template>
                <v-list flat>
                  <v-list-item @click="getActaCompromiso">
                    <v-list-item-content>
                      <v-list-item-title>Acta de Compromiso</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="info"
                @click="editItem(item)"
                v-on="on"
              >
                fa-pen
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="error"
                @click="deleteItem(item)"
                v-on="on"
              >
                fa-trash
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="orange lighten-1"
                @click="pdfItem(item)"
                v-on="on"
              >
                fa-file-pdf
              </v-icon>
            </template>
            <span>Generar PDF</span>
          </v-tooltip>
          <v-tooltip
            v-if="item.state!='Enviado'"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="success"
                @click="sendItem(item)"
                v-on="on"
              >
                fa-clipboard-check
              </v-icon>
            </template>
            <span>Enviar</span>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                icon
                color="green"
                dark
                @click="initialize()"
                v-on="on"
              >
                <v-icon
                  dark
                  v-on="on"
                >
                  fa-sync-alt
                </v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
        </template>
        <template v-slot:footer>
          <v-row
            class="mt-2"
            align="center"
            justify="center"
          >
            <v-spacer />
            <span class="grey--text">Items por página</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>fa-angle-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <span
              class="mr-4
                grey--text"
            >
              Página {{ page }} de {{ numberOfPages }}
            </span>
            <v-btn
              fab
              x-small
              dark
              color="blue darken-3"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>fa-angle-left</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              color="blue darken-3"
              class="ml-1 mr-4"
              @click="nextPage"
            >
              <v-icon>fa-angle-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialog"
        max-width="1000px"
        scrollable
        persistent
        fullscreen
      >
        <v-card>
          <div>
            <v-toolbar
              dark
              color="primary"
            >
              <span class="headline">{{ formTitle }}</span>
              <v-spacer />
              <v-btn
                text
                @click="close"
              >
                X
              </v-btn>
            </v-toolbar>
          </div>

          <v-card-text>
            <FormProyect
              ref="project"
              :project-edited="projectEdited"
              :edited-index="editedIndex"
              @close3="close3"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              @click="save"
            >
              Guardar Borrador
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="close"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Modal donde Muestra los errores-->
      <v-dialog
        v-model="dialogError"
        scrollable
        max-width="600px"
      >
        <v-card>
          <v-system-bar
            dark
            color="red"
            dense
            flat
          >
            <span class="white--text"> Errores Encontrados </span>
            <v-spacer />
            <v-btn
              text
              small
              @click="dialogError = false"
            >
              X
            </v-btn>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <ul>
              <li
                v-for="(error, it) in errors"
                :key="`error${it}`"
              > 
                {{ error }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
  import FilterQuery from '../general/FilterQuery';
  import { createLinkFile, removeAccents } from '../../util/functions';
  import { URL_APP } from "../../util/constants";
  import FormProyect from './FormProyect';
  export default{
    name: 'Proyect',
    components: { FilterQuery, FormProyect },
    data() {
      return {
        loading: false,
        dialog: false,
        dialogError: false,
        errors: [],
        search: '',
        selected: [],
        itemsPerPageArray: [5, 10, 15, 20, 30, 50, 100],
        page: 1,
        itemsPerPage: 5,
        headers: [
          { text: 'Id', value: 'id' },
          { text: 'Convocatoria', value: 'announcement.name' },
          { text: 'Titulo', value: 'title' },
          { text: 'Seccional', value: 'sectional.name' },
          { text: 'Estado', value: 'state' },
          { text: 'Actions', value: 'action', sortable: false },
        ],
        desserts: [],
        filters: [],
        fieldFilters:[
          {field:'state', name:'Estado', type:'select', options:["Borrador", "Enviado"]},
          {field:'announcement.name', name:'Convocatoria', type:'text'},
          {field:'sectional.name', name:'Seccional', type:'text'},
        ],
        editedIndex: -1,
        projectEdited: {
          id: 0,
          announcement_id: 0,
          title: '',
          sectional_id: '',
          internal_investigators: [],
          external_investigators: [],
          attacheds: [],
          results: [],
          hours: '',
          state: "Borrador",
          announcement: {},
          type: {},
        },
        defaultItem: {
          id: 0,
          announcement_id: 0,
          title: '',
          sectional_id: '',
          internal_investigators: [],
          external_investigators: [],
          attacheds: [],
          results: [],
          hours: '',
          state: "Borrador",
          announcement: {},
          type: {},
        },
        valid: true
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Proyecto' : 'Editar Proyecto'
      },
      numberOfPages () {
        return Math.ceil(this.desserts.length / this.itemsPerPage)
      },
      path(){
        return this.$route.name =="projects"?true:false
      }
    },
    watch: {
      path () {
        this.initialize()
        this.filters = this.path?[
          {field:{field:'state', name:'Estado', type:'select', options:["Borrador", "Enviado"]}, type:'Igual', value_start:'Enviado', value_end:''}
        ]:[]
      }
    },

    mounted () {
      this.initialize()
      this.filters = this.path?[
        {field:{field:'state', name:'Estado', type:'select', options:["Borrador", "Enviado"]}, type:'Igual', value_start:'Enviado', value_end:''}
      ]:[]
    },
    methods: {
      initialize () {
        this.loading = true
        var url
        if(this.path){
          url = URL_APP+"/api/siiicca/projects"
        }else{
          url = URL_APP+"/api/siiicca/myprojects"
        }
        this.$http.get(url, {
          params: {
            filters: this.filters
          }
        })
        .then(response => {
          //Asigna los datos a los projectos
          this.desserts=response.data
          this.loading = false
        });
      },

      pdfItem (item) {
        var url = URL_APP+"/api/siiicca/projectpdf/" + item.id;
        var name = removeAccents(item.id+"."+item.title)+".pdf"
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response, name)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener el PDF",color:"error",timeout:1000})
          this.$root.$loadApp(false)
        });
      },

      getExcel () {
        var projects = []
        this.selected.forEach(function(project){
          projects.push(project.id)
        })
        var url = URL_APP+"/api/siiicca/getexcel/" + JSON.stringify(projects);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los Anexos",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      getAnexos () {
        var projects = []
        this.selected.forEach(function(project){
          projects.push(project.id)
        })
        var url = URL_APP+"/api/siiicca/getanexos/" + JSON.stringify(projects);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los Anexos",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      getActaInicio() {
        var projects = []
        this.selected.forEach(function(project){
          projects.push(project.id)
        })
        var url = URL_APP+"/api/siiicca/getactainicio/" + JSON.stringify(projects);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener las Actas de Inicio",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      getActaCompromiso() {
        var projects = []
        this.selected.forEach(function(project){
          projects.push(project.id)
        })
        var url = URL_APP+"/api/siiicca/getactacompromiso/" + JSON.stringify(projects);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener las Actas de Compromiso",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.projectEdited = Object.assign({}, item)
        if(this.projectEdited.state!="Borrador"){
          this.$root.$alert({text:"El Proyecto no tiene el Estado Necesario para Editar",color:"error",timeout:4000})
        }
        this.dialog = true
        //this.$refs.project.getMunicipios();
      },

      sendItem (item) {
        var url = "/api/siiicca/sendproject";
        this.$root.$confirm('Enviar Proyecto', 'El proyecto será enviado y no podrá ser modificado, sin embargo se realizará una validación, Realmente desea enviarlo?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.post(url, { id: item.id }).then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:1000})
            }).catch(error => {
              this.errors = error.response.data;
              this.dialogError = true;
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },
      
      deleteItem (item) {
        var url = "/api/siiicca/projects/" + item.id;
        this.$root.$confirm('Eliminar Proyecto', 'Esta Seguro de Eliminar este Proyecto?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url).then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:1000})
            }).catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:1000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.$root.$confirm('Cancelar Proyecto', 'Realmente desea Cancelar la edición del Proyecto de investigación?, NO será guardados los cambios', { color: 'red' })
        .then((res) => {
          if(res){
            this.close2()
            this.initialize()
            this.$root.$alert({text:"No se Guardó...",color:"warning",timeout:1000})
          }
        })
      },

      close2(){
        this.dialog = false
        setTimeout(() => {
          this.projectEdited = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      close3(){
        this.close2()
        this.initialize()
      },

      save () {
        this.$refs.project.save();
      },

    }
  }
</script>

import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/programs"

const state = {
  programs: []
}

const getters = {
  allPrograms: state => state.programs
}

const actions = {

  async getPrograms({commit}){
    return new Promise(function(resolve, reject){
      commit('setPrograms', [])
      axios.get(URL_).then(res=>{
        commit('setPrograms', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveProgram({commit}, program){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, program).then(res=>{
        commit('newProgram', res.data.program)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateProgram({commit}, program){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, program).then(res=>{
        //console.log(res.data)
        commit('updProgram', res.data.program)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteProgram({commit}, program){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${program.id}`).then(res=>{
        commit('delProgram', program)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setPrograms : (state, programs) => state.programs = programs,
  newProgram  : (state, program) => state.programs.unshift(program),
  updProgram  : (state, program) => {
    const index = state.programs.findIndex(u => u.id === program.id)
    if (index !== -1) state.programs.splice(index, 1, program)
  },
  delProgram  : (state, program) => state.programs = state.programs.filter(c => c.id !== program.id)
}

export default{
  state, getters, actions, mutations
}
<template>
  <v-overlay
    :value="overlay"
    :z-index="zindex"
    :absolute="false"
  >
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
    zindex: 10000
  }),
  methods: {
    change(value) {
      this.overlay = value
    },
  }
}
</script>

<template>
  <div>
    <v-data-table :headers="headers" :items="desserts" :loading="loading" :search="search" sort-by="name" class="elevation-1"
      :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fa-angle-double-left',
      lastIcon: 'fa-angle-double-right',
      prevIcon: 'fa-angle-left',
      nextIcon: 'fa-angle-right'
    }">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Grupos de Investigación</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-text-field v-model="search" append-icon="fa-search" label="Buscar" single-line hide-details />
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" small fab dark color="green darken-2" v-on="on" @click="exportData()">
                <v-icon dark>
                  fa-download
                </v-icon>
              </v-btn>
            </template>
            <span>Exportar</span>
          </v-tooltip>

          <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="users" v-model="valid">
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12">
                        <v-select v-model="editedItem.sectional_id" autofocus :items="sectionals"
                          :rules="rules.sectional" item-text="name" item-value="id" label="* Seccional" />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.name" label="* Nombre" :rules="rules.name" />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.colciencias" label="* Colciencias"
                          :rules="rules.colciencias" />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.url" label="Url" :rules="rules.url" />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete v-model="editedItem.investigation_lines" item-text="name" item-value="id"
                          :items="investigation_lines" attach chips label="Lineas de Investigación" multiple />
                      </v-col>
                      <v-col cols="12">
                        <v-switch v-model="editedItem.active" label="Estado" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="secondary" outlined @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="primary" outlined @click="save">
                  {{ viewMode }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.investigation_lines="{ item }">
        <span v-for="(inv_line, index) in item.investigation_lines" :key="index">
          &bull; {{ inv_line.name }} <!-- Viñeta -->
          <br v-if="index < item.investigation_lines.length - 1"> <!-- Salto de línea si no es el último elemento -->
        </span>
      </template>


      <template v-slot:item.roles="{ item }">
        <v-chip v-for="(rol, indexRol) in item.roles" :key="indexRol" color="secondary" dark>
          {{ rol }}
        </v-chip>
      </template>
      <template v-slot:item.active="props">
        {{ stateItem(props.item.active) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-1" color="info" @click="editItem(item)" v-on="on">
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-1" color="error" @click="deleteItem(item)" v-on="on">
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text icon color="green" dark v-on="on">
              <v-icon dark v-on="on">
                fa-sync-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>
        <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { URL_APP } from "../../util/constants";
import { createLinkFile } from "../../util/functions";
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
      search: '',
      selected: [],
      rules: {
        name: [
          v => !!v || 'Campo requerido',
          v => v.length >= 5 || 'Mínimo 5 caracteres',
          v => v.length < 255 || 'Máximo 255 letras',
        ],
        colciencias: [
          v => !!v || 'Campo Requerido',
          v => v.length < 50 || 'Máximo 50 letras',
        ],
      },
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'name' },
        { text: 'Líneas de investigación', value: 'investigation_lines' },
        { text: 'Seccional', value: 'sectional.name' },
        { text: 'Colciencias', value: 'colciencias' },
        { text: 'Estado', value: 'active' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      desserts: [],
      sectionals: [],
      investigation_lines: [],
      roles: [],
      editedIndex: -1,
      editedItem: { id_sectional: '', id: 0, colciencias: '', name: '', active: true },
      defaultItem: { id_sectional: '', id: 0, colciencias: '', name: '', active: true },
      valid: true
    }
  },

  computed: {
    ...mapGetters([ 'loading']),

    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
    },
    viewMode() {
      return this.editedIndex === -1 ? 'Guardar' : 'Editar'
    },
    currentUser() {
      return this.$store.getters.currentUser
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions(['chgLoad']),
    initialize() {
      if (typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
      this.getSectionals()
      this.getInvestigationLines()
      this.$http.get(URL_APP + '/api/siiicca/investigation_groups')
        .then(res => {
          this.desserts = res.data;
          this.$root.$loadApp(false)
        });
    },

    async exportData() {
      var url = URL_APP + "/api/siiicca/export_investigation_groups";
      this.chgLoad(true);
      await this.$http.get(url, { responseType: 'blob' })
        .then((response) => {
          createLinkFile(response)
          this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 })
          this.chgLoad(false);
        })
        .catch(() => {
          this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
          this.chgLoad(false);
        })
    },

    //Taer las Seccionales
    getSectionals() {
      let url = URL_APP + "/api/sectionals"
      this.$http.get(url)
        .then(res => {
          this.sectionals = res.data;
        });
    },

    //Taer las Seccionales
    getInvestigationLines() {
      let url = URL_APP + "/api/siiicca/investigation_lines"
      this.$http.get(url)
        .then(res => {
          this.investigation_lines = res.data;
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    stateItem(stateIt) {
      return stateIt ? 'Activo' : 'Inactivo'
    },

    deleteItem(item) {
      var url = URL_APP + "/api/siiicca/investigation_groups/" + item.id;
      confirm('Estas seguro de Eliminar este Registro?') && this.$http.delete(url).then(response => {
        this.initialize();
        this.$root.$alert({ text: response.data.message, color: "success", timeout: 1000 })
      }).catch(error => {
        console.error(error)
        this.$root.$alert({ text: "Imposible Eliminar Registro", color: "success", timeout: 2000 })
      });
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    async save() {
      this.$refs.users.validate()
      if (this.valid) {
        this.$root.$loadApp(true)
        var method1 = "POST"
        if (this.editedIndex > -1) {
          //Editar Item
          method1 = "PUT"
        }
        await this.$http({
          method: method1,
          url: URL_APP + '/api/siiicca/investigation_groups',
          data: this.editedItem
        })
          .then(response => {
            this.close()
            this.initialize();
            this.$root.$alert({ text: response.data.message, color: "success", timeout: 2000 })
          }).catch(error => {
            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 })
          })
        this.$root.$loadApp(false)
      } else {
        this.$root.$alert({ text: "Por favor revise los errores", color: "error", timeout: 2000 })
      }
    },

  }
}
</script>

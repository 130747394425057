<template>
  <div>
    <v-data-table
      :headers="getHeaders"
      :items="getItems"
      item-key="id"
      sort-by="name"
      class="elevation-1 mx-1 mt-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                x-small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="newItem()"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo Actor</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="900px"
            persistent
          >
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <span>{{ formTitle }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="mx-2 my-2"
                      x-small
                      dark
                      color="secondary"
                      v-on="on"
                      @click.stop="dialogHelp=true"
                    >
                      fa-question
                    </v-icon>
                  </template>
                  <span>Ayuda</span>
                </v-tooltip>
                <v-spacer />
                <v-btn
                  text
                  @click="cancel"
                >
                  X
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  @change="edited=true"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col
                        v-if="!type.includes('Productivo') && !type.includes('Estado') && !type.includes('Civil')"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="actorEdited.third.identification"
                          name="identification"
                          label="* Identificación"
                          :rules="rules.identification"
                          type="number"
                          @change="getActor()"
                        />
                      </v-col>
                      <v-col
                        v-if="!type.includes('Productivo') && !type.includes('Estado') && !type.includes('Civil')"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="actorEdited.third.name"
                          name="name"
                          label="* Nombre"
                          type="text"
                          :rules="rules.name"
                          required
                        />
                      </v-col>
                      <v-col
                        v-else
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="actorEdited.actor_name"
                          name="actorName"
                          :label="getActorName"
                          :rules="rules.actorName"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        v-if="type.includes('Docente') || (type.includes('Estudiante'))"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="actorEdited.link"
                          :items="links"
                          name="link"
                          :rules="rules.link"
                          label="Tipo de Vinculación"
                        />
                      </v-col>
                      <v-col
                        v-if="!type.includes('Productivo') && !type.includes('Estado') && !type.includes('Civil')"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="actorEdited.third.email"
                          name="email"
                          label="* Email"
                          :rules="rules.email"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        v-else
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="actorEdited.email"
                          name="email"
                          label="* Email"
                          :rules="rules.email"
                          type="text"
                          required
                        />
                      </v-col>
                      <template v-if="getEntity()">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="actorEdited.entity_name"
                            name="entityName"
                            :label="nameEntity"
                            :rules="rules.entity_name"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          v-if="type.includes('Productivo')"
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete
                            v-model="actorEdited.economic_sector_id"
                            item-text="name"
                            item-value="id"
                            :items="economicSectors"
                            name="entitySector"
                            :rules="rules.entitySector"
                            label="* Sector Economico"
                            required
                          />
                        </v-col>
                        <v-col
                          v-if="type.includes('Productivo')"
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete
                            v-model="actorEdited.sector"
                            :items="sectors"
                            name="sector"
                            :rules="rules.sector"
                            label="* Sector"
                            required
                          />
                        </v-col>
                        <v-col
                          v-if="actorEdited.link && (actorEdited.link.includes('Nacional'))"
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete
                            v-model="actorEdited.city_id"
                            clearable
                            required
                            :items="cities"
                            item-value="id"
                            item-text="name"
                            :rules="rules.city_id"
                            label="* Seleccione la Ciudad"
                          />
                        </v-col>
                        <v-col
                          v-if="actorEdited.link && (actorEdited.link.includes('Internacional') || actorEdited.link.includes('Movilidad'))"
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete
                            v-model="actorEdited.country_id"
                            clearable
                            required
                            :items="countries"
                            item-value="id"
                            item-text="name"
                            :rules="rules.country_id"
                            label="* Seleccione el País"
                          />
                        </v-col>
                      </template>
                      <v-col
                        v-if="type.includes('Docente')"
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="actorEdited.investigation_group_id"
                          clearable
                          :items="investigationGroups"
                          item-value="id"
                          item-text="name"
                          text="name"
                          label="Grupo de Investigación"
                          placeholder="Seleccione el grupo de investigación"
                          required
                        />
                      </v-col>
                      <template v-if="type.includes('Sector Productivo') || type.includes('Estado') || type.includes('Sociedad Civil')">
                        <v-col
                          v-if="type=='Estado'"
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="actorEdited.actor_position"
                            name="actorPosition"
                            label="* Cargo en la Entidad"
                            :rules="rules.actorPosition"
                            type="text"
                            required
                          />
                        </v-col>
                      </template>
                      <v-col
                        v-if="!type.includes('Egresado') && !type.includes('Estudiante')"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="actorEdited.rol"
                          :items="roles"
                          name="rol"
                          :rules="rules.rol"
                          label="* Rol"
                        />
                      </v-col>
                      <v-col
                        v-if="type.includes('Egresado')"
                        cols="12"
                      >
                        <Egresado
                          :programs="actorEdited.programs"
                          :array-programs="programs"
                        />
                      </v-col>
                      <template v-if="type.includes('Estudiante')">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete v-if="actorEdited.link.includes('Movilidad')"
                            v-model="actorEdited.program_id"
                            item-value="id"
                            item-text="name"
                            :items="allPrograms"
                            name="program_id"
                            :rules="rules.program_id"
                            label="* Programa"
                            required
                            :prepend-icon="'fa-plus'"
                            @click:prepend="$refs.formProgram.newItem()"
                          />
                          <v-autocomplete v-else
                            v-model="actorEdited.program_id"
                            item-value="id"
                            item-text="name"
                            :items="allPrograms"
                            name="program_id"
                            :rules="rules.program_id"
                            label="* Programa"
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="actorEdited.semester"
                            name="semester"
                            label="* Semestre"
                            :rules="rules.semester"
                            type="number"
                            required
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="save"
                >
                  {{ viewMode }}
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="cancel()"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.state="{ item }">
      <v-chip v-if="item.state"
        :color="getColor(item.state)"
        dark
      >
        {{ item.state }}
      </v-chip>
    </template>


      <template v-slot:item.programs="{ item }">
        <v-chip
          v-for="program in item.programs"
          :key="program.pivot.program_id"
          color="secondary"
          dark
          class="mr-1"
        >
          {{ program.name }}
        </v-chip>
      </template>


      <template v-slot:item.action="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on } " v-if="item.state == 'Activo' || !item.state">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom >
          <template v-slot:activator="{ on }" v-if="item.state == 'Activo'">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogHelp"
      max-width="500"
      @keydown.esc="dialogHelp = false"
    >
      <v-card>
        <v-system-bar
          dark
          color="secondary"
          dense
          flat
        >
          <span class="white--text">Ayuda</span>
          <v-spacer />
          <v-btn
            text
            small
            @click="dialogHelp = false"
          >
            X
          </v-btn>
        </v-system-bar>
        <v-card-text>
          <v-container fluid>
            <v-row
              justify="center"
              align-content="center"
              align="center"
            >
              Los Campos con Asterisco (*) son obligatorios<br>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FormProgram ref="formProgram" :simpleForm="true"/>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../util/constants";
  import FormProgram from '../general/Program/FormProgram'
  import Egresado from './Egresado';
export default {
  name: "ActorInternal",
  components: {
    Egresado, FormProgram
  },
  props: {
    actors: {
      default: {}
    },
    investigationGroups: {
      default: []
    },
    programs: {
      default: []
    },
    type:{
      type: String,
      default: "Interno"
    }
  },
  data() {
    return {
      editedIndex: -1,
      edited:true,
      menFechGrad: false,
      valid: null,
      dialog: false,
      dialogHelp: false,
      typeIdent: [
        {id:'CC', name:'Cédula de Ciudadanía'},
        {id:'TI', name:'Tarjeta de Identidad'},
        {id:'CI', name:'Cédula de Identidad'},
        {id:'TP', name:'Tarjeta Pasaporte'},
        {id:'RC', name:'Registro Civil'},
        {id:'CE', name:'Cédula de Extranjería'},
      ],
      links:this.type.includes("Docente")?[
        "Tiempo Completo",
        "Medio Tiempo",
        "Hora Catedra",
        "Docente Nacional",
        "Docente Internacional",
      ]: ["Estudiante USTA", "Estudiante Movilidad"],
      roles:this.type.includes("Docente")?[
        "Co-Tutor", "Evaluador", "Asesor",
      ]:[ "Co-Tutor", "Co-ejecutor", "Evaluador",
        "Asesor", "Beneficiario"  ],
      sectionals: [],
      groups:[],
      sectors:["Público", "Privado"],
      economicSectors:[],
      faculties:[],
      typeInves: [],
      countries: [],
      cities: [],
      typeColci: [
        {id:'IS', name:'Investigador Senior'},
        {id:'I', name:'Investigador Asociado'},
        {id:'IJ', name:'Investigador Junior'},
        {id:'ED', name:'Estudiante de Doctorado'},
        {id:'EM', name:'Estudiante de Maestria o Especialidad Clínica'},
        {id:'JI', name:'Joven Investigador'},
        {id:'IPO', name:'Investigador del Programa Ondas'},
        {id:'EP', name:'Estudiante de Pregado'},
        {id:'IVD', name:'Integrante Vinculado con Doctorado'},
        {id:'IVM', name:'Integrante Vinculado con Maestria'},
        {id:'IVE', name:'Integrante Vinculado con Especialización'},
        {id:'IVP', name:'Integrante Vinculado con Pregado'},
        {id:'IV', name:'Integrante Vinculado'},
      ],
      actorEdited: {
        id: null,
        type: '',
        rol: '',
        link: '',
        entity_name:'',
        actor_name:'',
        actor_position:'',
        investigation_group_id: null,
        entity_id: null,
        sectional_id: null,
        country_id: null,
        city_id: null,
        email: '',
        program:{},
        programs:[],
        entity: {
          nit: '',
          name: '',
          country_id: '',
        },
        third:{
          id: '',
          type_id: '',
          identification: '',
          name: '',
          email: '',
        },
      },
      defaultItem: {
        id: null,
        type: '',
        rol: '',
        link: '',
        entity_name:'',
        actor_name:'',
        actor_position:'',
        investigation_group_id: null,
        entity_id: null,
        sectional_id: null,
        country_id: null,
        city_id: null,
        state: 'Activo',
        email: '',
        program:{},
        programs:[],
        entity: {
          nit: '',
          name: '',
          country_id: '',
        },
        third:{
          id: '',
          type_id: '',
          identification: '',
          name: '',
          email: '',
        },
      },
      rules: {
          type: [v => (v || '').length > 0 || 'Campo requerido'],
          name: [
            v => (v || '').length > 0 || 'Nombre requerido',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          actorName: [
            v => (v || '').length > 0 || 'Nombre requerido',
            v => v.length < 250 || 'Máximo 250 letras',
          ],
          training: [
            v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 80 || 'Máximo 80 letras',
          ],
          program_id: [ v => !!v || 'Campo Requerido',  ],
          link: [v => (v || '').length > 0 || 'Campo requerido'],
          type_id: [v => (v || '').length > 0 || 'Tipo de Identificación requerido'],
          identification: [v => (v || '').length > 0 || 'Identificación requerido',
            v => v.length < 100 || 'Máximo 15 letras',
          ],
          email: [
            v => (v || '').length > 0 || 'Email requerido',
            v => v.length < 150 || 'Máximo 150 letras',
            v => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(v) || 'Email Invalido.'
            }
          ],
          sectional_id: [ v => !!v || 'Campo Requerido',  ],
          country_id: [ v => !!v || 'Campo Requerido',  ],
          entitySector: [ v => !!v || 'Campo Requerido',  ],
          sector: [ v => !!v || 'Campo Requerido',  ],
          city_id: [ v => !!v || 'Campo Requerido',  ],
          entity_name: [v => (v || '').length > 0 || 'Nombre requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
          rol: [v => (v || '').length > 0 || 'Campo Requerido', ],
          actorPosition: [v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
      },
    }
  },

  computed: {
    ...mapGetters(['allPrograms']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo '+this.type : 'Editar '+this.type
    },
    viewMode () {
      return this.editedIndex === -1 ? 'Guardar' : 'Editar'
    },
    getActorName(){
      if(this.type.includes('Productivo'))
        return "* Nombre de Empresario"
      else
        return "* Nombre del Actor"
    },
    getHeaders(){
      var headers= [
          { text: 'Id', value: 'third.identification' },
          { text: 'Nombre', value: 'third.name' },
      ]
      if(this.type=="Docente")
        headers = [
          { text: 'Id', value: 'third.identification' },
          { text: 'Nombre', value: 'third.name' },
          { text: 'Vinculación', value: 'link' },
          { text: 'Rol', value: 'rol' },
          { text: 'Estado', value: 'state' },

        ]
      if(this.type=="Egresado")
        headers = [
          { text: 'Id', value: 'third.identification' },
          { text: 'Nombre', value: 'third.name' },
          { text: 'Programas', value: 'programs' },
          { text: 'Estado', value: 'state' },

        ]
      if(this.type=="Estudiante")
        headers = [
          { text: 'Id', value: 'third.identification' },
          { text: 'Nombre', value: 'third.name' },
          { text: 'Programa', value: 'program.name' },
          { text: 'Estado', value: 'state' },

        ]
      if(this.type=="Sector Productivo" || this.type=="Estado")
        headers = [
          { text: 'Nombre', value: 'actor_name' },
          //{ text: 'Empresa', value: 'entity.name' },
          { text: 'Rol', value: 'rol' },
        ]
      if(this.type=="Sociedad Civil")
        headers = [
          { text: 'Nombre', value: 'actor_name' },
        ]
      headers = [...headers, { text: 'Actions', value: 'action', sortable: false },]
      return headers
    },
    getItems(){
      var type = this.type
      return this.actors.filter( actor => actor.type.includes(type) )
    },
    nameEntity(){
      if(this.type.includes("Docente"))
        return "* Institución de Procedencia"
      else if(this.type.includes("Egresado"))
        return "* Nombre de la Entidad donde labora"
      else
        return "* Nombre de la Entidad"
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {
    ...mapActions(['chgLoad']),
    initialize () {
      //this.getTypeInves();
      //Funcion para inicializar el Componente
      this.getSectionals()
      this.getCountries();
      this.getCities();
      this.getSectors()
    },

    getTypeInves() {
      //Tipos de Investigadores Según el tipo de Grupo de Investigación
      this.typeInves = []
      var existePrincipalL = false
      this.actors.forEach(function (investigator) {
        if(investigator.type_investigator=="Principal"){
          existePrincipalL = true
        }
      })
      if(!existePrincipalL || this.actorEdited.type_investigator=="Principal"){
        this.typeInves.push({id:'Principal', name:'Investigador Principal'})
      }
      this.typeInves.push({id:'Coinvestigador', name:'Coinvestigador'})
    },

    //Taer los Países
    async getCountries () {
      try{
        var res = await this.$http.get(URL_APP+"/api/countries")
        this.countries = res.data;
      }catch(err){console.error(err)}
    },

    //Taer las Ciudades
    async getCities () {
      try{
        var res = await this.$http.get(URL_APP+"/api/cities")
        this.cities = res.data;
      }catch(err){console.error(err)}
    },

    //Taer las Seccionales
    getSectionals () {
      try{
        var res = this.$http.get(URL_APP+"/api/selsectionals")
        this.sectionals = res.data;
      }catch(err){console.error(err)}
    },

    //Taer los Sectores
    async getSectors () {
      try{
        var res = await this.$http.get(URL_APP+"/api/seleconomic_sectors")
        this.economicSectors = res.data;
      }catch(err){console.error(err)}
    },

    getEntity(){
      if(this.actorEdited.link)
      if(this.actorEdited.link.includes('Nacional') || this.actorEdited.link.includes('Internacional') || this.actorEdited.link.includes('Movilidad'))
        return true
      if(this.type.includes('Egresado') || this.type.includes('Productivo') || this.type.includes('Estado'))
        return true
      return false
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.actorEdited = Object.assign({}, this.defaultItem)
        this.actorEdited.entity = Object.assign({}, this.defaultItem.entity)
        this.actorEdited.third = Object.assign({}, this.defaultItem.third)
        this.actorEdited.program = Object.assign({}, this.defaultItem.program)
        this.editedIndex = -1
      }, 300)
    },

     deleteItem (item) {

      this.$root.$confirm('Eliminar '+this.type, 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          // console.log(item.state);
          this.$http.put(URL_APP+"/api/siiicca/remove_strategy_actor/" + item.id)
          this.$root.$alert({text:"¡ "+this.type+" Eliminado !",color:"success",timeout:1000})
          // Esto es provisional, toca revisarlo a fondo
          if(item.state == 'Activo'){
            item.state = 'Inactivo'
          }
          else if(item.state == 'Inactivo'){
            item.state = 'Activo'
          }
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    getColor (state) {
        if (state == 'Activo') return 'green'
        else if (state == 'Inactivo') return 'red'
        // else return 'green'
      },


    cancel () {
      if(this.edited){
        var txtCancel = ""
        if(this.editedIndex > -1){
          txtCancel = "Cancelar Edición"
        }else{
          txtCancel = "Cancelar Adición"
        }
        this.$root.$confirm(txtCancel, 'Esta Seguro?, Podría Perder Los Datos editados', { color: 'warning' })
        .then((res) => {
          if(res){
            this.close()
            this.$root.$alert({text:"¡ Cancelado !",color:"success",timeout:1000})
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      }else{
        this.close()
      }
    },

    editItem (item) {
      this.editedIndex = this.actors.indexOf(item)
      this.actorEdited = Object.assign({}, item)
      //this.getTypeInves()
      this.dialog = true
    },

    newItem () {
      //this.getTypeInves()
      this.actorEdited = Object.assign({}, this.defaultItem)
      this.actorEdited.entity = Object.assign({}, this.defaultItem.entity)
      this.actorEdited.third = Object.assign({}, this.defaultItem.third)
      this.actorEdited.program = Object.assign({}, this.defaultItem.program)
      this.actorEdited.programs = []
      this.editedIndex = -1
      this.dialog = true
    },

    async getActor () {
      var actor = await this.actors.find(actor => actor.third.identification == this.actorEdited.third.identification)
      if(!actor){
        this.$root.$loadApp(true)
        await this.$http.get(URL_APP+"/api/siiicca/get_leader", {params: {"identification":this.actorEdited.third.identification}}).then(response => {
          if (response.data.third.id) this.actorEdited.third.id = response.data.third.id
          if (response.data.third.name) this.actorEdited.third.name = response.data.third.name
          if (response.data.third.email) this.actorEdited.third.email = response.data.third.email
          //
          if (response.data.third.training) this.actorEdited.training = response.data.third.training
          if (response.data.third.link) this.actorEdited.link = response.data.third.link
          if (response.data.third.sectional_id) this.actorEdited.sectional_id = response.data.third.sectional_id
          if (response.data.third.investigation_group_id) this.actorEdited.investigation_group_id = response.data.third.investigation_group_id

          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(()=> {
          this.$root.$loadApp(false)
        });
      }else{
        this.$root.$alert({text:"Actor Ya Existe como: "+actor.type,color:"error",timeout:2000})
      }
    },

    async save() {
      this.actorEdited.type = this.type
      this.$refs.form.validate()
      if(!this.valid){
        this.$root.$alert({text:"No se puede Guardar, por favor revise los errores",color:"error",timeout:2000})
      }else{
        var finded = false
        var actor = null;
        if(this.actorEdited.third.identification)
          actor = await this.actors.find(actor => actor.third.identification == this.actorEdited.third.identification)
        else
          actor = await this.actors.find(actor => actor.actor_name == this.actorEdited.actor_name)
        if(this.editedIndex==-1){
          finded = actor ? true : false
        }
        if(!finded){ //Si es nuevo
          var _this2 = this
          var program = await this.programs.find(program => program.id == _this2.actorEdited.program_id)
          this.actorEdited.program = program?program:'';
          //Añadimos El Investigador al Grupo
          if(this.editedIndex>-1)
            Object.assign(this.actors[this.editedIndex], this.actorEdited)
          else
            this.actors.push(this.actorEdited)
          this.close()
        }else{
          console.log(this.editedIndex)
          this.$root.$alert({text:"Actor Ya Existe como: "+actor.type,color:"error",timeout:2000})
        }
      }
    }
  }
}
</script>

<template>
  <v-container
    fluid
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12 pa-3">
          <v-card-text>
            <div class="layout column align-center">
              <img
                class="primary"
                src="../../assets/santoto.png"
                alt="USTAB"
                width="90%"
              >
              <h4 class="flex my-4 primary--text">
                Recupera tu contraseña
              </h4>
            </div>
            <v-alert
              v-if="errorT"
              prominent
              type="error"
              class="mb-4"
            >
              {{ errorT }}
            </v-alert>
            
            <v-form
              v-else
              ref="recover"
              v-model="valid"
              @submit.prevent="false"
            >
              <v-text-field
                v-model="form.emailVerif"
                append-icon="fa-at"
                name="emailVerif"
                label="Correo Electrónico"
                type="text"
                :rules="rules.emailRules"
                required
                autofocus
              />
              <v-text-field
                v-model="form.password"
                append-icon="fa-lock"
                name="password"
                label="Contraseña"
                type="password"
                :rules="rules.passwordRules"
                required
              />
              <v-text-field
                v-model="form.password_confirmation"
                append-icon="fa-lock"
                name="password_confirmation"
                label="Repita la Contraseña"
                type="password"
                :rules="rules.passVerifiRules"
                required
              />
              <!--{{-- CAPTCHA --}}-->
              <vue-recaptcha
                ref="invisibleRecaptcha"
                :sitekey="sitekey"
                @verify="onVerify"
                @expired="onExpired"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container class="my-2">
              <v-row>
                <v-col cols="5">
                  <v-btn
                    text
                    color="orange darken-1"
                    to="/login"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="disableButton"
                    @click="recoverPassword"
                  >
                    Cambiar Contraseña
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import {ofuscaMail} from '@/js/util';
import { URL_APP } from "../../util/constants";
import VueRecaptcha from 'vue-recaptcha'
import * as constants from '../../util/constants'
export default {
    name: 'Findrecover',
    components: {VueRecaptcha},
    props: {
        source: String
    },
    data() {
        return {
            sitekey: constants.KEY_CLIENT, 
            errorT: '',
            loading: false,
            form:{
                email: '',
                token: '',
                id: '',
                captcha:'',
                emailVerif: '',
                password: '',
                password_confirmation: ''
            },
            rules: {
              emailRules: [
                v => !!v || 'Correo Requerido',
                v => /.+@.+\..+/.test(v) || 'Correo Invalido',
                v => v==this.form.email || 'Correo Electrónico no coincide',
              ],
              passwordRules: [
                v => !!v || 'Contraseña Requerida',
                v => v.length >= 8 || 'Mínimo 8 caracteres',
              ],
              passVerifiRules: [
                v => v==this.form.password || 'Contraseñas no coinciden',
              ],
            },
            valid: false,
        }
    },
    computed: {
        disableButton: function (){
            if(this.valid){
              if(this.form.captcha!="" && this.form.password == this.form.password_confirmation){
                return false
              }else{
                return true
              }
            }else{
              return true
            }
        }
    },
    mounted: function () {
      //Revisa que exista el token
      this.$http.get(URL_APP+'/api/password/find/'+this.$route.params.t)
        .then((res)=>{
            if(res.data.error){
              this.errorT = res.data.error
            }else{
              this.errorT = "";
              this.form.email = res.data.email;
              this.form.token = res.data.token;
              this.form.id = res.data.id;
              let recaptchaScript = document.createElement ('script'); 
              recaptchaScript.setAttribute('src', "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"); 
              document.head.appendChild(recaptchaScript); 
            }
        })
        .catch((error)=>{ console.error(error)
          this.errorT = "Ha Ocurrido un error con el Token"
        });
    },
    methods: {
        recoverPassword(){
          this.loading = true;
              this.$http.post(URL_APP+'/api/password/reset', this.form)
              .then((res)=>{
                  this.$root.$alert({text:res.data.message,color:"success",timeout:2000})
                  this.$router.push({path: '/login'});
                  this.loading = false;
              })
              .catch((error)=>{
                this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
                this.loading = false;
              });
        },
        onVerify: function (response) {
          this.form.captcha = response;
        },
        onExpired: function () {
          this.form.captcha = "";
        },
        resetRecaptcha () {
          this.$refs.invisibleRecaptcha.reset() // Direct call reset method
          this.onExpired()
        }
    }
}
</script>

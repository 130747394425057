<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="name"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'fa-angle-double-left',
        lastIcon: 'fa-angle-double-right',
        prevIcon: 'fa-angle-left',
        nextIcon: 'fa-angle-right'
      }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>Tipos de Productos de Investigación</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
          />
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="dialog = true"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            max-width="1000px"
            persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="users"
                  v-model="valid"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="* Nombre"
                          :rules="rules.name"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="editedItem.active"
                          label="Estado"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="secondary"
                  outlined
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  @click="save"
                >
                  {{ viewMode }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.roles="{ item }">
        <v-chip
          v-for="(rol, indexRol) in item.roles"
          :key="indexRol"
          color="secondary"
          dark
        >
          {{ rol }}
        </v-chip>
      </template>
      <template v-slot:item.active="props">
        {{ stateItem(props.item.active) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              color="green"
              dark
              v-on="on"
            >
              <v-icon
                dark
                v-on="on"
              >
                fa-sync-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>
        <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      multi-line="multi-line"
      :color="color"
      right="right"
      :timeout="timeout"
      top="top"
    >
      {{ text }}
      <v-btn
        outlined
        small
        @click="snackbar = false"
      >
        X
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    data() {
      return {
        dialog: false,
        search: '',
        snackbar: false,
        text: '',
        color: '',
        timeout: 3000,
        selected: [],
        rules: {
            name: [
              v => !!v || 'Campo requerido',
              v => v.length >= 5 || 'Mínimo 5 caracteres',
            ],
            email: [
              v => !!v || 'Campo Requerido'
            ],
            identity: [
              v => !!v || 'Campo Requerido'
            ],
            sectional: [
              v => !!v || 'Campo Requerido'
            ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Estado', value: 'active' },
            { text: 'Actions', value: 'action', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {id_sectional:'', id: 0, identity: 0, name: '', email: '', roles: [], password: '', active: true},
        defaultItem: {id_sectional:'', id: 0, identity: 0, name: '', email: '', roles: [], password: '', active: true},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/siiicca/product_types')
        .then(res => {
          this.desserts = res.data;
          this.$root.$loadApp(false)
        });
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      deleteItem (item) {
        var url = URL_APP+"/api/siiicca/product_types/" + item.id;
        confirm('Estas seguro de Eliminar este Registro?') && this.$http.delete(url).then(response => {
          this.initialize();
          this.verSnackbar(response.data.message);
        }).catch(error => {
          console.error(error)
          this.verSnackbar("Imposible Eliminar Registro","error");
       });
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      verSnackbar(txt, colorA){
        this.color = colorA;
        this.text = txt;
        this.snackbar = true;
      },

      save () {
        this.$refs.form.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "PUT"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/siiicca/product_types',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },

    }
  }
</script>

<template>
  <v-card>
    <v-card-title>
      Actividades del Plan de Trabajo
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2 my-2"
            small
            fab
            dark
            color="primary"
            v-on="on"
            @click.stop="newItem"
          >
            <v-icon dark>
              fa-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Nuevo</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="activities"
        hide-default-footer
        itemid="description"
        :items-per-page="30"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="info"
                @click="editItem(item)"
                v-on="on"
              >
                fa-pen
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="error"
                @click="deleteItem(item)"
                v-on="on"
              >
                fa-trash
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!--Dialogo de Actividades-->
      <v-dialog
        v-model="dialog"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-text>
            <v-form
              ref="formActivity"
              v-model="valid"
              name="formActivity"
            >
              <v-textarea
                id="description"
                v-model="itemEdited.description"
                label="Actividad"
                :rules="rules.activity"
                required
                hide-details="auto"
              />                
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              @click="save"
            >
              Aceptar
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="close"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card> 
</template>
<script>
  export default {
    name: 'Activity',
    props: {
      activities: {
        type: Array,
        default: null
      }
    },
    data(){
      return {
        dialog: false,
        valid: null,
        rules: {
          activity: [
            v => (v || '').length > 0 || 'Campo requerido',
          ],
        },
        itemEdited: {},
        headers: [
          { text: 'Actividad', value: 'description' },
          { text: 'Actions', value: 'action', sortable: false },
        ],
        indexEditing : -1,
      }
    },
    methods: {
      newItem(){
        this.dialog = true
        this.focusElement()
      },

      editItem(item){
        this.indexEditing = this.activities.indexOf(item)
        this.itemEdited = Object.assign({}, item)
        this.dialog = true
        this.focusElement()
      },

      focusElement(){
        setTimeout(() => {
          document.getElementById("description").focus()
        }, 300)
      },

      close(){
        this.dialog = false
        setTimeout(() => {
          this.itemEdited = {}
          this.indexEditing = -1
        }, 300)
      },

      deleteItem (item) {
        const index = this.activities.indexOf(item)
        this.$root.$confirm('Eliminar Actividad ', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.activities.splice(index, 1)
            this.$root.$alert({text:"¡ Registro Eliminado !",color:"success",timeout:1000})
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      save(){
        this.$refs.formActivity.validate()
        if(this.valid){
          if(this.indexEditing>-1)
            Object.assign(this.activities[this.indexEditing], this.itemEdited)
          else
            this.activities.push(this.itemEdited)
          this.close()
        }else{
          this.$root.$alert({text:"Por favor llenar todos los campos",color:"error",timeout:2000})
        }
      },  

    }
  }
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import { getLocalUser } from '../helpers/auth';
import users from './modules/general/users.js'
import roles from './modules/general/roles.js'
import permissions from './modules/general/permissions.js'
import sectionals from './modules/general/sectionals.js'
import campuses from './modules/general/campuses.js'
import cities from './modules/general/cities.js'
import programs from './modules/general/programs.js'

const user = getLocalUser();

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: user,
    load: false,
    configuration: {}
  },
  getters:{
    currentUser(state){
      return state.currentUser;
    },
    loading(state){
      return state.load;
    }
  },
  mutations: {
    loginSuccess(state, payload){
      var fecexpire = (new Date() / 1000) + payload.token.expires_in;
      var token = payload.token
      token.expire = fecexpire
      state.currentUser = Object.assign({}, payload.user, { token: token });
      localStorage.setItem("user", JSON.stringify(state.currentUser));
    },
    logout(state){
      localStorage.removeItem('user');
      state.currentUser = null;
    },
    setLoad : (state, load) => state.load = load,
  },
  actions: {
    /*login(context){
      context.commit('login');
    }*/
    chgLoad({commit}, load){
      commit('setLoad', load)
    },
  },
  modules: {
    users, roles, sectionals,
    permissions, programs,
    campuses, cities
  }
})

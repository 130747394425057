<template>
  <v-card>
    <v-card-title>
      Programas
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2 my-2"
            small
            fab
            dark
            color="primary"
            v-on="on"
            @click.stop="newItem"
          >
            <v-icon dark>
              fa-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Nuevo</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="programs"
        hide-default-footer
        itemid="description"
        :items-per-page="30"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="info"
                @click="editItem(item)"
                v-on="on"
              >
                fa-pen
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                color="error"
                @click="deleteItem(item)"
                v-on="on"
              >
                fa-trash
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!--Dialogo de Programas-->
      <v-dialog
        v-model="dialog"
        persistent
        :overlay="false"
        max-width="1000px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              name="form"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-autocomplete
                      id="program_id"
                      v-model="itemEdited.pivot.program_id"
                      :items="arrayPrograms"
                      item-value="id"
                      item-text="name" 
                      :rules="rules.programs"
                      label="* Seleccione el Programa"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="itemEdited.pivot.year"
                      type="number"
                      required
                      :rules="rules.year"
                      label="Año"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              @click="save"
            >
              Aceptar
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="close"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card> 
</template>
<script>
  export default {
    name: 'Egresado',
    props: {
      programs: {
        default: []
      },
      arrayPrograms: {
        default: []
      }
    },
    data(){
      return {
        dialog: false,
        valid: null,
        rules: {
          program_id: [ v => !!v || 'Campo Requerido',  ],
          year: [
            v => v.length < 5 || 'Máximo 4 números',
            v => (v || '').length > 0 || 'Campo requerido',
            v => {
              var today = new Date();
              var year = today.getFullYear();
              return (v<=year)?true:"El año debe ser menor o igual al actual"
            },
          ],
        },
        itemEdited: {name:'',pivot:{program_id:'', year:''}},
        headers: [
          { text: 'Programa', value: 'name' },
          { text: 'Año', value: 'pivot.year' },
          { text: 'Actions', value: 'action', sortable: false },
        ],
        indexEditing : -1,
      }
    },
    methods: {
      newItem(){
        this.dialog = true
        this.focusElement()
      },

      editItem(item){
        this.indexEditing = this.programs.indexOf(item)
        this.itemEdited = Object.assign({}, item)
        this.dialog = true
        this.focusElement()
      },

      focusElement(){
        setTimeout(() => {
          document.getElementById("program_id").focus()
        }, 300)
      },

      close(){
        this.dialog = false
        setTimeout(() => {
          this.itemEdited = {name:'',pivot:{program_id:'', year:''}},
          this.indexEditing = -1
        }, 300)
      },

      deleteItem (item) {
        const index = this.programs.indexOf(item)
        this.$root.$confirm('Eliminar Registro ', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.programs.splice(index, 1)
            this.$root.$alert({text:"¡ Registro Eliminado !",color:"success",timeout:1000})
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      async save(){
        this.$refs.form.validate()
        var _this2 = this
        this.itemEdited.name = await this.arrayPrograms.find(program => program.id == _this2.itemEdited.pivot.program_id).name;
        if(this.valid){
          if(this.indexEditing>-1)
            Object.assign(this.programs[this.indexEditing], this.itemEdited)
          else
            this.programs.push(this.itemEdited)
          this.close()
        }else{
          this.$root.$alert({text:"Por favor llenar todos los campos",color:"error",timeout:2000})
        }
      },  

    }
  }
</script>

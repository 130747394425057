var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"sort-by":"title"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Convocatorias")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" fa-plus ")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])]),_c('v-dialog',{attrs:{"max-width":"1000px","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","rules":_vm.rules.name,"autofocus":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"rules":_vm.rules.type,"items":_vm.types,"label":"* Tipo de Convocatoria","placeholder":"Seleccione el Tipo de Convocatoria","required":""},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"description","label":"Descripción","rules":_vm.rules.description},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha de Inicio","rules":_vm.rules.fecha_inicio,"prepend-icon":"fa-calendar-alt","readonly":""},model:{value:(_vm.editedItem.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.editedItem, "fecha_inicio", $$v)},expression:"editedItem.fecha_inicio"}},on))]}}]),model:{value:(_vm.menFechIni),callback:function ($$v) {_vm.menFechIni=$$v},expression:"menFechIni"}},[_c('v-date-picker',{attrs:{"min":_vm.fechaHoy()},on:{"input":function($event){_vm.menFechIni = false}},model:{value:(_vm.editedItem.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.editedItem, "fecha_inicio", $$v)},expression:"editedItem.fecha_inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha Final","rules":_vm.rules.fecha_fin,"prepend-icon":"fa-calendar-alt","readonly":""},model:{value:(_vm.editedItem.fecha_fin),callback:function ($$v) {_vm.$set(_vm.editedItem, "fecha_fin", $$v)},expression:"editedItem.fecha_fin"}},on))]}}]),model:{value:(_vm.menFechFin),callback:function ($$v) {_vm.menFechFin=$$v},expression:"menFechFin"}},[_c('v-date-picker',{attrs:{"min":_vm.editedItem.fecha_inicio},on:{"input":function($event){_vm.menFechFin = false}},model:{value:(_vm.editedItem.fecha_fin),callback:function ($$v) {_vm.$set(_vm.editedItem, "fecha_fin", $$v)},expression:"editedItem.fecha_fin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"label":"Estado","color":"primary"},model:{value:(_vm.editedItem.state),callback:function ($$v) {_vm.$set(_vm.editedItem, "state", $$v)},expression:"editedItem.state"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary darken-1","outlined":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary darken-1","outlined":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.viewMode)+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.state",fn:function(props){return [_vm._v(" "+_vm._s(_vm.stateItem(props.item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" fa-pen ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" fa-trash ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}},{key:"no-data",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green","dark":""},on:{"click":function($event){return _vm.initialize()}}},on),[_c('v-icon',_vm._g({attrs:{"dark":""}},on),[_vm._v(" fa-sync-alt ")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
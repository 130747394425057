<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <span class="headline">Informe Final</span>
          <v-spacer />
          <v-btn
            text
            @click="$emit('close')"
          >
            X
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <v-form
            ref="form1"
            v-model="valid"
          >
            Enviar Informe Final Estrategia {{ strategyEdited.id + " - "+ strategyEdited.strategy }}
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="final_report_file"
                    :rules="rules.attached"
                    accept=".pdf"
                    placeholder="Subir Informe Final"
                    label=""
                    required
                    prepend-icon="fa-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        small
                        label
                        color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="accept"
                    :rules="rules.accept"
                    label="He generado y revisado el Archivo PDF y se encuentra OK"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save"
          >
            Aceptar
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('close')"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <!--Modal donde muestra el campo a subir-->
    <v-dialog
      v-model="dialogError"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-system-bar
          dark
          color="red"
          dense
          flat
        >
          <span class="white--text"> Errores Encontrados </span>
          <v-spacer />
          <v-btn
            text
            small
            @click="dialogError = false"
          >
            X
          </v-btn>
        </v-system-bar>
        <v-divider />
        <v-card-text>
          <ul>
            <li
              v-for="(error, it) in errors"
              :key="`error${it}`"
            > 
              {{ error }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'FinishStrategy',
    props: {
      strategyEdited: {},
      dialog: null,
    },
    data() {
      return {
        final_report_file: null,
        accept : null,
        dialogError : null,
        errors:[],
        rules: {
          attached: [
            v => {
              if(v){
                return true
              }else{
                return 'Campo requerido'
              }
            },
            v => !v || v.size < 100000000 || 'No puede Subir Archivos mayor a 10 GB!',
          ],
          accept: [
            v => !!v || 'Debe aceptar para continuar!',
          ]
        },
        valid: null
      }
    },
    watch: {
      dialog () {
        this.initialize()
      }
    },
    methods: {
      initialize(){
        this.accept = null
        this.final_report_file = null
      },

      async save(){
        this.$refs.form1.validate()
        if(this.valid){
          var formData = new FormData();
          formData.append("final_report_file", this.final_report_file)
          formData.append("id", this.strategyEdited.id)
          this.$root.$loadApp(true)
          try{
            var response = await this.$http.post(URL_APP+'/api/siiicca/finish_strategy',formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })
            this.$emit('close')
            this.$root.$alert({text:"Guardado Correctamente",color:"success",timeout:1000})
            this.$emit('initialize');
          }catch(error) {
            if(Array.isArray(error.response.data)){
              this.errors = error.response.data
              this.dialogError = true
            }else{
              this.$root.$alert({text:"No se pudo Guardar el Informe Final, por favor Revise",color:"error",timeout:2000})
            }
            //this.dialogError = true
          }        
          this.$root.$loadApp(false)
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-1 mx-1 mt-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'fa-angle-double-left',
      lastIcon: 'fa-angle-double-right',
      prevIcon: 'fa-angle-left',
      nextIcon: 'fa-angle-right'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Periodo de Estrategia")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" fa-plus ")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])])],1)]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" fa-pen ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" fa-trash ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])}),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1000px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.cancel()}}},Object.assign({}, tooltip)),[_vm._v(" X ")])]}}])},[_c('span',[_vm._v("Cerrar")])])],1),_c('v-card-text',{attrs:{"color":"white"}},[_c('v-form',{ref:"form1",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"* Descripción","required":"","rules":_vm.rules.description},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"fa-calendar-alt","rules":_vm.rules.date_start,"readonly":""},model:{value:(_vm.editedItem.date_start),callback:function ($$v) {_vm.$set(_vm.editedItem, "date_start", $$v)},expression:"editedItem.date_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogStartDate),callback:function ($$v) {_vm.dialogStartDate=$$v},expression:"dialogStartDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dialogStartDate = false}},model:{value:(_vm.editedItem.date_start),callback:function ($$v) {_vm.$set(_vm.editedItem, "date_start", $$v)},expression:"editedItem.date_start"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"fa-calendar-alt","rules":_vm.rules.date_end,"readonly":""},model:{value:(_vm.editedItem.date_end),callback:function ($$v) {_vm.$set(_vm.editedItem, "date_end", $$v)},expression:"editedItem.date_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogEndDate),callback:function ($$v) {_vm.dialogEndDate=$$v},expression:"dialogEndDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dialogEndDate = false}},model:{value:(_vm.editedItem.date_end),callback:function ($$v) {_vm.$set(_vm.editedItem, "date_end", $$v)},expression:"editedItem.date_end"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    right
    :timeout="snackbar.timeout"
    top
  >
    <span :class="textColor">{{ snackbar.text }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        :class="textColor"
        @click="snackbar.show = false"
      >
        X
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
/**
 * Vuetify SnackBar Alert component
 *
 * Insert component where you want to use it:
 * <SnackbarAlert ref="alert"></SnackbarAlert>
 *
 * Call it:
 * this.$refs.alert.open('Delete', 'Are you sure?', { color: 'red' }).then((alert) => {})
 * Or use await:
 * if (await this.$refs.alert.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$alert
 * <template>
 *   <v-app>
 *     ...
 *     <SnackbarAlert ref="alert"></SnackbarAlert>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$alert = this.$refs.alert.open
 * }
 */
export default {
  data: () => ({
    snackbar: {
      show: false,
      text: '',
      color: '',
      timeout: 1000,
    },
  }),
  computed: {
    textColor(){
      if(this.snackbar.color.includes('light')){
        return 'black--text'
      }else if(this.snackbar.color.includes('warning')){
        return 'primary--text'
      }else{
        return 'white--text'
      }
    }
  },
  methods: {
    open(options) {
      this.snackbar = Object.assign(this.snackbar, options)
      this.snackbar.show = true
    },
  }
}
</script>
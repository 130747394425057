<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="projectEdited.results"
      sort-by="kind"
      class="elevation-1 mx-1 mt-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>Resultados Esperados</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                x-small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="newItem()"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo Resultado</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="900px"
            persistent
          >
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <span>{{ formTitle }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="mx-2 my-2"
                      x-small
                      dark
                      color="secondary"
                      @click.stop="dialogHelp=true"
                      v-on="on"
                    >
                      fa-question
                    </v-icon>
                  </template>
                  <span>Ayuda</span>
                </v-tooltip>
                <v-spacer />
                <v-btn
                  text
                  @click="cancel"
                >
                  X
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="resultEdited.product_type_id"
                          :rules="rules.product_type_id"
                          :items="productTypes"
                          item-text="name"
                          item-value="id"
                          label="* Tipología de Producto"
                          placeholder="Seleccionar..."
                          required
                          clearable
                          @change="getProducts"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="resultEdited.product_id"
                          :rules="rules.product_id"
                          :items="products"
                          item-text="name"
                          item-value="id"
                          label="* Producto de Investigación"
                          placeholder="Seleccionar..."
                          clearable
                          required
                          @change="getProduct"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="resultEdited.quantity" 
                          name="quantity"
                          label="* Cantidad" 
                          type="number" 
                          :rules="rules.quantity"
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="save"
                >
                  {{ viewMode }}
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="cancel()"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogHelp"
      max-width="500"
      scrollable
      @keydown.esc="dialogHelp = false"
    >
      <v-card>
        <v-system-bar
          dark
          color="secondary"
          dense
          flat
        >
          <span class="white--text">Ayuda</span>
          <v-spacer />
          <v-btn
            text
            small
            @click="dialogHelp = false"
          >
            X
          </v-btn>
        </v-system-bar>
        <v-card-text>
          <v-container fluid>
            <v-row
              justify="center"
              align-content="center"
              align="center"
            >
              <p class="font-italic text-justify">
                La convocatoria interna de investigación considera como factor relevante que los resultados derivados de la investigación mejoren la clasificación de los grupos de investigación de la USTA Bucaramanga, por lo que se solicita que como productos resultados de la presente convocatoria se incluya por lo menos: <br> <br>
                <ul>
                  <li>Un (1) producto Tipo TOP o Tipo A. (Asociado a las tipologías generación de conocimiento y desarrollo tecnológico e innovación).</li>
                  <li>Un (1) producto de apropiación social del conocimiento (Asociado a la tipología apropiación social del conocimiento).</li>
                  <li>Un (1) producto de actividades relacionadas con la Formación de Recurso Humano para la CTeI Tipo A o Tipo B. (Asociado a la tipología Formación de Recurso Humano para la CTeI).</li>
                </ul>
              </p>
              <p class="font-italic text-justify">
                Los Resultados esperados deben ser coherentes con los objetivos específicos y con la metodología planteada. Los resultados/productos pueden clasificarse en cuatro tipologías: <br><br>
              </p>
              <span class="subtitle-2 text-justify">Actividades de Generación de Nuevo Conocimiento</span>
              <p class="font-italic text-justify">
                (Incluye resultados/productos que corresponden a nuevo conocimiento científico que puedan verificarse a través de publicaciones científicas de Artículos de investigación en revistas reconocidas por Colciencias, Libros resultados de investigación; Capítulos en libros resultados de investigación, Productos tecnológicos patentados o en proceso de concesión de patente, Variedad vegetal y nueva raza animal, Obras o productos de investigación- creación en Artes, Arquitectura y Diseño.)<br>
              </p>
              
              <span class="subtitle-2 text-justify">Actividades de Apropiación Social del Conocimiento</span>
              <p class="font-italic text-justify">
                (Incluye aquellos resultados/productos que son estrategias o medios para divulgar o transferir el conocimiento o tecnologías generadas en el proyecto a los beneficiarios potenciales y a la sociedad en general.  Estos productos corresponden a Participación ciudadana en CTeI y creación, Estrategias pedagógicas para el fomento de la CTeI, Comunicación social del conocimiento, Circulación de conocimiento especializado)<br>
              </p>
              
              <span class="subtitle-2 text-justify">Actividades de Desarrollo Tecnológico e Innovación</span>
              <p class="font-italic text-justify">
                (Incluye resultados/productos que corresponden a Productos tecnológicos certificados o validados, Productos empresariales, Regulaciones, normas, reglamentos o legislaciones)<br>
              </p>
              
              <span class="subtitle-2 text-justify">Actividades de Formación del Recurso Humano</span>
              <p class="font-italic text-justify">
                (Incluye resultados/productos tales como Tesis de doctorado, Trabajo de grado de Maestría, Trabajo de grado de pregrado, Proyectos de investigación y desarrollo.)<br>
              </p>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: "Result",
  props: {
    projectEdited: {
      type: Object,
      default: null
    },
    arrayProducts: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      editedIndex: -1,
      menFechGrad: false,
      headers: [
        { text: 'Tipo', value: 'product_type.name' },
        { text: 'Producto', value: 'product.name' },
        { text: 'Cantidad', value: 'quantity' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      products:[],
      valid: null,
      dialog: false,
      dialogHelp: false,
      countNew: 0,
      resultEdited: {
        id: 'n_',
        product_type_id: '',
        product_id: '',
        product: {id:0, name:''},
        product_type: {id:0, name:''},
        quantity: 1,
      },
      defaultItem: {
        id: 'n_',
        product_type_id: '',
        product_id: '',
        product: {id:0, name:''},
        product_type: {id:0, name:''},
        quantity: 1,
      },
      rules: {
        product_type_id: [
          v => !!v || 'Campo Requerido',
        ],
        product_id: [
          v => !!v || 'Campo Requerido',
        ],
        quantity: [
          v => !!v || 'Campo Requerido',
          v => v>0 || 'Cantidad Mayor a 0'
        ],
      },
      productTypes: [],
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Resultado Projecto: '+ this.projectEdited.title : 'Editar Resultado Proyecto: '+ this.projectEdited.title
    },
    viewMode () {
      return this.editedIndex === -1 ? 'Guardar' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {

    initialize () {
      //Funcion para inicializar el Componente
      this.countNew = 0
    },

    close () {
      this.dialog = false
      this.resultEdited = Object.assign({}, this.defaultItem)
      this.resultEdited.product = Object.assign({}, this.defaultItem.product)
      this.resultEdited.product_type = Object.assign({}, this.defaultItem.product_type)
      this.editedIndex = -1
    },

    deleteItem (item) {
      const index = this.projectEdited.results.indexOf(item)
      this.$root.$confirm('Eliminar Resultado', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.projectEdited.results.splice(index, 1)
          this.$root.$alert({text:"¡ Resultado Eliminado !",color:"success",timeout:1000})
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    cancel () {
      var txtCancel = ""
      if(this.editedIndex > -1){
        txtCancel = "Cancelar Edición"
      }else{
        txtCancel = "Cancelar Adición"
      }
      this.$root.$confirm(txtCancel, 'Esta Seguro?, Podría Perder Los Datos editados', { color: 'warning' })
      .then((res) => {
        if(res){
          this.close()
          this.$root.$alert({text:"¡ Cancelado !",color:"success",timeout:1000})
        }else{
          this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
        }
      })
    },

    editItem (item) {
      this.editedIndex = this.projectEdited.results.indexOf(item)
      this.resultEdited = Object.assign({}, item)
      this.dialog = true
      this.getProductTypes()

    },

    newItem () {
      if(this.projectEdited.hours!='' && this.projectEdited.hours!=null){
        this.getProductTypes()
        //this.resultEdited = Object.assign({}, this.defaultItem)
        //this.editedIndex = -1
        this.resultEdited.id = 'n_'+this.countNew;
        this.dialog = true
      }else{
        this.$root.$alert({text:"Debe seleccionar la intensidad horaria",color:"error",timeout:2000})
      }
    },

    getProductTypes(){
      var _this2 = this
      this.productTypes = []
      //Recorrer los productos para traer los Tipos de Productos
      this.arrayProducts.forEach(function(product){
        product.types.forEach(function(type){
          var bandera = true
          _this2.productTypes.forEach(function(productType){
            if(productType.id == type.id || type.pivot.hours != _this2.projectEdited.hours)
              bandera = false
          })
          if(bandera)
            _this2.productTypes.push(type)
        })
      })
      this.getProducts()
    },

    getProducts(){
      var _this2 = this
      this.products = []
      //Recorrer los productos para traer los productos
      this.arrayProducts.forEach(function(product){
        var bandera = false
        product.types.forEach(function(type){
          //Busca el tipo de producto
          if(type.id == _this2.resultEdited.product_type_id){
            _this2.resultEdited.product_type.id = type.id
            _this2.resultEdited.product_type.name = type.name
          }
          if(_this2.resultEdited.product_type_id == type.id && type.pivot.hours == _this2.projectEdited.hours)
          bandera = true
        })
        if(bandera)
        _this2.products.push(product)
      })
    },

    getProduct(){
      var _this2 = this
      //Recorrer los productos para traer los productos
      this.arrayProducts.forEach(function(product){
          if(product.id == _this2.resultEdited.product_id){
            _this2.resultEdited.product.id = product.id
            _this2.resultEdited.product.name = product.name
          }
      })
    },

    save() {
      this.$refs.form.validate()
      if(!this.valid){
        this.$root.$alert({text:"No se puede Guardar, por favor revise los errores",color:"error",timeout:2000})
      }else{
        //Añadimos El Resultado al Proyecto
        if (this.editedIndex > -1) {
          Object.assign(this.projectEdited.results[this.editedIndex], this.resultEdited)
          this.$root.$alert({text:"Edición Correcta",color:"success",timeout:1000})
          this.close()
        }else{
          //Nuevo, revisar que no exista
          var exist = false
          var _this2 = this
          this.projectEdited.results.forEach(function(result){
            if(result.product_type_id == _this2.resultEdited.product_type_id && 
                result.product_id == _this2.resultEdited.product_id){
              exist = true
            }
          })
          if(!exist){
            this.projectEdited.results.push(this.resultEdited)
            this.$root.$alert({text:"Añadido Correctamente",color:"success",timeout:1000})
            this.close()
            this.countNew ++
          }else{
            this.$root.$alert({text:"Producto ya Existe",color:"error",timeout:1000})
          }
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <v-data-table :headers="headers" :items="allPrograms" :search="search" :loading="loading" sort-by="title"
      class="elevation-1" :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fa-angle-double-left',
      lastIcon: 'fa-angle-double-right',
      prevIcon: 'fa-angle-left',
      nextIcon: 'fa-angle-right'
    }">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Programas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-text-field v-model="search" append-icon="fa-search" label="Buscar" single-line hide-details />
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on"
                @click.stop="$refs.formProgram.newItem()">
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" small fab dark color="green darken-2" v-on="on" @click="exportData()">
                <v-icon dark>
                  fa-download
                </v-icon>
              </v-btn>
            </template>
            <span>Exportar</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-1" color="info" @click="$refs.formProgram.editItem(item)" v-on="on">
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-1" color="error" @click="deleteItem(item)" v-on="on">
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text icon color="green" dark @click="initialize()" v-on="on">
              <v-icon dark v-on="on">
                fa-sync-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <FormProgram ref="formProgram" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { URL_APP } from "../../../util/constants";
import { createLinkFile } from "../../../util/functions";
import FormProgram from './FormProgram'
export default {
  name: 'Program',
  components: {
    FormProgram
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'name' },
        { text: 'SAC', value: 'codesac' },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
    }
  },

  computed: {
    ...mapGetters(['allPrograms', 'loading']),
    currentUser() {
      return this.$store.getters.currentUser
    }
  },

  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions(['getPrograms', 'deleteProgram', 'chgLoad']),

    async initialize() {
      this.chgLoad(true)
      await this.getPrograms()
      this.chgLoad(false)
    },

    async exportData() {
      var url = URL_APP + "/api/programsExport";
      this.chgLoad(true);
      await this.$http.get(url, { responseType: 'blob' })
        .then((response) => {
          createLinkFile(response)
          this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 })
          this.chgLoad(false);
        })
        .catch(() => {
          this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
          this.chgLoad(false);
        })
    },

    async deleteItem(item) {
      this.$root.$confirm("Eliminar Programa", 'Esta seguro que desea eliminar el registro ?', { color: 'red' })
        .then(async (res) => {
          if (res) {
            this.chgLoad(true)
            try {
              var msg = await this.deleteProgram(item)
              this.$root.$alert({ text: msg, color: "success", timeout: 3000 })
            } catch (error) {
              console.error(error)
              this.$root.$alert({ text: "Imposible Eliminar Registro", color: "error", timeout: 3000 })
            }
            this.chgLoad(false)
          } else {
            this.$root.$alert({ text: "¡ Acción Cancelada !", color: "warning", timeout: 1000 })
          }
        })
    },

  }
}
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      sort-by="id"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'fa-angle-double-left',
        lastIcon: 'fa-angle-double-right',
        prevIcon: 'fa-angle-left',
        nextIcon: 'fa-angle-right'
      }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>Campos de Acción</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
          />
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="dialog = true"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            max-width="1000px"
            persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="form1"
                  v-model="valid"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="* Nombre"
                          :rules="rules.name"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="editedItem.active"
                          label="Estado"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="secondary"
                  outlined
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  @click="save"
                >
                  {{ viewMode }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.active="props">
        {{ stateItem(props.item.active) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              color="green"
              dark
              v-on="on"
            >
              <v-icon
                dark
                v-on="on"
              >
                fa-sync-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>
        <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    data() {
      return {
        dialog: false,
        loading: false,
        search: '',
        selected: [],
        rules: {
            name: [
              v => !!v || 'Campo requerido',
              v => v.length >= 5 || 'Mínimo 5 caracteres',
            ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Estado', value: 'active' },
            { text: 'Actions', value: 'action', sortable: false },
        ],
        items: [],
        editing: false,
        editedItem: { id: 0, name: '', active: true},
        defaultItem: { id: 0, name: '', active: true},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return !this.editing ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editing? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      async initialize () {
        this.items = []
        this.loading = true
        await this.$http.get(URL_APP+'/api/siiicca/action_fields')
        .then(res => {
          this.items = res.data;
        });
        this.loading = false
      },

      editItem (item) {
        this.editing = true
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      async deleteItem (item) {
        this.$root.$confirm("Eliminar Campo de Acción", 'Esta seguro que desea eliminar el registro ?', { color: 'red' })
        .then(async (res) => {
          if(res){
            await this.$http.delete(URL_APP+"/api/siiicca/action_fields/" + item.id).then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
            }).catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            });
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editing = false
        }, 300)
      },

      save () {
        this.$refs.form1.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editing) {
            //Editar Item
            method1 = "PUT"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/siiicca/action_fields',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },

    }
  }
</script>

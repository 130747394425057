<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    scrollable
    persistent
    fullscreen
  >
    <v-card>
      <div>
        <v-toolbar dark color="primary">
          <span class="headline">{{ formTitle }}</span>
          <v-spacer />
          <v-btn text @click="close"> X </v-btn>
        </v-toolbar>
      </div>
      <v-card-text>
        <v-form ref="form1" v-model="valid">
          <v-stepper v-model="e1" vertical class="mt-2">
            <template v-for="(itemStep, key) in steps">
              <v-stepper-step
                :key="'step' + itemStep.id"
                :step="key + 1"
                editable
              >
                {{ itemStep.name }}
              </v-stepper-step>
              <v-stepper-content
                :key="'step-content' + itemStep.id"
                :step="key + 1"
              >
                <template v-if="itemStep.id == 1">
                  <v-autocomplete
                    :id="'step' + itemStep.id"
                    v-model="itemEdited.sectional_id"
                    :items="sectionals"
                    item-value="id"
                    item-text="name"
                    :rules="rules.sectional_id"
                    label="* Seleccione la Seccional"
                    @change="getGroups"
                  />

                  <!-- Agregando el periodo
                  <v-autocomplete
                    v-model="itemEdited.period_id"
                    :items="periods"
                    item-value="id"
                    item-text="description"
                    :rules="rules.period_id"
                    label="* Seleccione el Periodo"
                  /> -->

                  <v-autocomplete
                    v-model="itemEdited.strategy"
                    item-value="name"
                    item-text="name"
                    :disabled="editing"
                    :items="strategies"
                    :rules="rules.strategy"
                    label="* Estrategia de Formación"
                  />
                  <div class="font-italic caption" v-html="hintStrategy"></div>
                </template>
                <template v-if="itemStep.id == 2">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        :id="'step' + itemStep.id"
                        v-model="itemEdited.leader.sectional_id"
                        :items="sectionals"
                        item-value="id"
                        item-text="name"
                        :rules="rules.sectional_id"
                        label="* Seleccione la Seccional"
                        readonly
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="itemEdited.leader.third.identification"
                        label="* Identificación"
                        required
                        :rules="rules.identification"
                        type="number"
                        @change="getLeader()"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="itemEdited.leader.third.name"
                        label="* Nombre"
                        required
                        :rules="rules.name"
                        type="text"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="itemEdited.leader.third.email"
                        label="* Email"
                        required
                        :rules="rules.email"
                        type="text"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="itemEdited.leader.investigation_group_id"
                        clearable
                        :items="investigationGroups"
                        item-value="id"
                        item-text="name"
                        :rules="rules.group_id"
                        label="Grupo de Investigación"
                        @change="getInvestigationLines"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="itemEdited.leader.faculty_id"
                        :items="sectionalFaculties"
                        item-value="id"
                        item-text="name"
                        :rules="rules.faculty_id"
                        label="* Seleccione la Facultad"
                      />
                    </v-col>

                    <!-- SE CAMBIA ESTA OPCIÓN QUE ES DEL PUNTO 3 POR PETICIÓN DE
                    INVESTIGACIONES -->

                    <v-col
                      v-if="itemEdited.strategy.includes('Semilleros')"
                      cols="12"
                    >
                      <v-text-field
                        v-model="itemEdited.proyect_name"
                        label="Proyecto de Semillero(Si Aplica)"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-if="itemStep.id == 3">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        :id="'step' + itemStep.id"
                        v-model="itemEdited.divisions"
                        multiple
                        :items="sectionalDivisions"
                        item-value="id"
                        item-text="name"
                        :rules="rules.divisions"
                        label="* Seleccione la(s) Division(es) donde se implementará dicha estrategia"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="itemEdited.faculties"
                        multiple
                        :items="sectionalFaculties"
                        item-value="id"
                        item-text="name"
                        :rules="rules.faculties"
                        label="* Seleccione la(s) Facultad(es)"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        v-model="itemEdited.programs"
                        multiple
                        :items="sectionalPrograms"
                        item-value="id"
                        item-text="name"
                        :rules="rules.programs"
                        label="* Seleccione los Programas"
                      />
                    </v-col>

                    <v-col
                      v-if="itemEdited.strategy.includes('Semilleros')"
                      cols="12"
                    >
                      <!-- <v-autocomplete
                        v-model="itemEdited.research_hotbed_id"
                        :items="researchHotbeds"
                        item-value="id"
                        item-text="name"
                        label="* Semillero de Investigación"
                        :rules="rules.research_hotbed_id"
                        hide-details="auto"
                      /> -->

                      <v-autocomplete
                        v-model="itemEdited.research_hotbed"
                        multiple
                        :items="researchHotbeds"
                        item-value="id"
                        item-text="name"
                        :rules="rules.research_hotbed_id"
                        label="* Semillero de Investigación"
                      />
                    </v-col>

                    <v-col
                      v-if="itemEdited.strategy == 'Grupos de Estudio'"
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="itemEdited.subjects"
                        multiple
                        :items="subjects"
                        item-value="id"
                        item-text="name"
                        :rules="rules.subject_id"
                        label="* Seleccione la(s) Asignatura(s)"
                      />
                    </v-col>

                    <v-col
                      v-if="!itemEdited.strategy.includes('Semilleros')"
                      cols="12"
                    >
                      <v-text-field
                        v-model="itemEdited.name"
                        :label="getNameStrategy"
                        :rules="rules.name"
                        hide-details="auto"
                      />
                    </v-col>

                    <!-- <v-col
                      v-if="itemEdited.strategy.includes('Semilleros')"
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="itemEdited.research_hotbed_id"
                        :items="researchHotbeds"
                        item-value="id"
                        item-text="name"
                        label="* Semillero de Investigación"
                        :rules="rules.research_hotbed_id"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col
                      v-if="itemEdited.strategy.includes('Semilleros')"
                      cols="12"
                    >
                      <v-text-field
                        v-model="itemEdited.proyect_name"
                        label="Proyecto de Semillero(Si Aplica)"
                        hide-details="auto"
                      />
                    </v-col> -->
                    <v-col
                      v-if="
                        itemEdited.strategy == 'Grupos de Estudio' ||
                        itemEdited.strategy == 'Grupos de Trabajo' ||
                        itemEdited.strategy.includes('Semilleros')
                      "
                      cols="12"
                    >
                      <v-textarea
                        v-model="itemEdited.overall_objective"
                        label="* Objetivo General"
                        :rules="rules.overall_objective"
                        hide-details="auto"
                        rows="2"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        v-model="itemEdited.methodology"
                        label="* Metodología"
                        :rules="rules.methodology"
                        hide-details="auto"
                        rows="2"
                      />
                    </v-col>

                    <v-col
                      v-if="itemEdited.strategy == 'Grupos de Estudio'"
                      cols="12"
                    >
                      <v-textarea
                        v-model="itemEdited.specific_goal"
                        label="* Objetivos Específicos"
                        :rules="rules.specific_goal"
                        hide-details="auto"
                        rows="2"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="itemEdited.duration"
                        label="* Duración de la estrategia (meses)"
                        type="number"
                        :rules="rules.duration"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col
                      v-if="itemEdited.strategy != 'Grupos de Trabajo'"
                      cols="12"
                    >
                      <v-textarea
                        v-model="itemEdited.problem_nucleus"
                        :label="getProblem"
                        :rules="rules.problem_nucleus"
                        hide-details="auto"
                        rows="2"
                      />
                    </v-col>
                    <v-col
                      v-if="itemEdited.strategy == 'Grupos de Trabajo'"
                      cols="12"
                    >
                      <v-menu
                        ref="menu1"
                        v-model="menuDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="itemEdited.conformation_date"
                            label="Fecha Inicio de conformación del Grupo de Trabajo"
                            :rules="rules.conformation_date"
                            prepend-icon="fa-calendar"
                            v-bind="attrs"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          ref="picker1"
                          v-model="itemEdited.conformation_date"
                          @change="saveDate"
                        />
                      </v-menu>
                    </v-col>

                    <v-col
                      v-if="itemEdited.strategy == 'Colectivos Académicos'"
                      cols="12"
                    >
                      <v-text-field
                        v-model="itemEdited.reward"
                        label="Reto, Concurso o premio"
                        :rules="rules.reward"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col
                      v-if="itemEdited.strategy == 'Colectivos Académicos'"
                      cols="12"
                    >
                      <v-text-field
                        v-model="itemEdited.entity"
                        label="Entidad"
                        :rules="rules.entity"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12">
                      <Activity :activities="itemEdited.activities" />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="itemEdited.investigation_lines"
                        :items="investigationLines"
                        item-value="id"
                        item-text="name"
                        multiple
                        :rules="rules.investigation_lines"
                        label="* Seleccione Linea(s) de Investigación"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="itemEdited.action_fields"
                        :items="actionFields"
                        item-value="id"
                        item-text="name"
                        multiple
                        :rules="rules.action_fields"
                        label="* Seleccione Campo(s) de Acción"
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-if="itemStep.id == 4">
                  <ActorInternal
                    :id="'step' + itemStep.id"
                    :actors="itemEdited.actors"
                    type="Docente"
                    :investigation-groups="investigationGroups"
                    :programs="programs"
                  />
                </template>

                <template v-if="itemStep.id == 5">
                  <ActorInternal
                    :id="'step' + itemStep.id"
                    :actors="itemEdited.actors"
                    type="Egresado"
                    :investigation-groups="investigationGroups"
                    :programs="programs"
                  />
                </template>

                <template v-if="itemStep.id == 6">
                  <ActorInternal
                    :id="'step' + itemStep.id"
                    :actors="itemEdited.actors"
                    type="Estudiante"
                    :investigation-groups="investigationGroups"
                    :programs="programs"
                  />
                </template>

                <template v-if="itemStep.id == 7">
                  <ActorInternal
                    :id="'step' + itemStep.id"
                    :actors="itemEdited.actors"
                    type="Sector Productivo"
                    :investigation-groups="investigationGroups"
                    :programs="programs"
                  />
                </template>

                <template v-if="itemStep.id == 8">
                  <ActorInternal
                    :id="'step' + itemStep.id"
                    :actors="itemEdited.actors"
                    type="Estado"
                    :investigation-groups="investigationGroups"
                    :programs="programs"
                  />
                </template>

                <template v-if="itemStep.id == 9">
                  <ActorInternal
                    :id="'step' + itemStep.id"
                    :actors="itemEdited.actors"
                    type="Sociedad Civil"
                    :investigation-groups="investigationGroups"
                    :programs="programs"
                  />
                </template>

                <template v-if="itemStep.id == 10">
                  <Products
                    :id="'step' + itemStep.id"
                    :products="itemEdited.products"
                    :array-products="products"
                  />
                </template>

                <template v-if="itemStep.id == 11">
                  * Carta de Autorización
                  <v-file-input
                    :id="'step' + itemStep.id"
                    v-if="!itemEdited.approvement_letter"
                    v-model="itemEdited.approvement_letter_file"
                    :rules="rules.attached"
                    accept=".pdf"
                    placeholder="Subir Archivo"
                    label=""
                    required
                    prepend-icon="fa-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <v-card v-else max-width="500" class="ml-1">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              icon
                              small
                              color="green"
                              dark
                              @click="getFile('approvement')"
                              v-on="on"
                            >
                              <v-icon dark v-on="on">
                                fa-cloud-download-alt
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar</span>
                        </v-tooltip>
                      </v-list-item-icon>
                      <v-list-item-content>
                        {{ itemEdited.approvement_letter }}
                      </v-list-item-content>
                      <v-list-item-action
                        v-if="$route.name == 'strategies' ? true : false"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              icon
                              small
                              color="red"
                              dark
                              @click="delFile('approvement')"
                              v-on="on"
                            >
                              <v-icon dark v-on="on"> fa-trash </v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </template>

                <v-btn
                  class="mt-3"
                  color="primary"
                  @click="nextStep(key, itemStep)"
                >
                  Siguiente
                </v-btn>
              </v-stepper-content>
            </template>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" outlined @click="save">
          {{ viewMode }}
        </v-btn>
        <v-btn color="secondary" outlined @click="close"> Cancelar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { URL_APP } from "../../util/constants";
import ActorInternal from "./ActorInternal";
import Activity from "./Activity";
import Products from "./Products";
import {
  objectToFormData,
  createLinkFile,
  removeAccents,
  toScroll,
} from "../../util/functions";

export default {
  name: "FormStrategy",
  components: {
    ActorInternal,
    Activity,
    Products,
  },
  props: {
    strategyEdited: {},
    editing: {
      type: Boolean,
      default: -1,
    },
  },
  data() {
    return {
      getNameStrategy: "",
      getProblem: "",
      edited: false,
      dialog: false,
      itemEdited: this.strategyEdited,
      e1: 1,
      valid: null,
      menuDate: false,
      rules: {
        sectional_id: [(v) => (v || 0) > 0 || "Campo requerido"],
        // period_id: [(v) => (v || 0) > 0 || "Campo requerido"],
        divisions: [(v) => (v || "").length > 0 || "Campo requerido"],
        faculties: [(v) => (v || "").length > 0 || "Campo requerido"],
        faculty_id: [(v) => (v || 0) > 0 || "Campo requerido"],
        programs: [(v) => (v || "").length > 0 || "Campo requerido"],
        action_fields: [(v) => (v || "").length > 0 || "Campo requerido"],
        investigation_lines: [(v) => (v || "").length > 0 || "Campo requerido"],
        strategy: [(v) => (v || "").length > 0 || "Campo requerido"],
        subject_id: [(v) => (v || "").length > 0 || "Campo requerido"],
        research_hotbed_id: [(v) => (v || "").length > 0 || "Campo requerido"],
        methodology: [(v) => (v || "").length > 0 || "Campo requerido"],

        // research_hotbed_id: [(v) => (v || 0) > 0 || "Campo requerido"],
        name: [
          (v) => (v || "").length > 0 || "Campo requerido",
          (v) => (v || "").length < 250 || "Máximo 250 letras",
        ],
        problem_nucleus: [(v) => (v || "").length > 0 || "Campo requerido"],
        conformation_date: [(v) => (v || "").length > 0 || "Campo requerido"],
        overall_objective: [(v) => (v || "").length > 0 || "Campo requerido"],
        specific_goal: [(v) => (v || "").length > 0 || "Campo requerido"],
        duration: [(v) => (v || "").length > 0 || "Campo requerido"],
        academic_period: [(v) => (v || "").length > 0 || "Campo requerido"],
        identification: [
          (v) => (v || "").length > 0 || "Campo requerido",
          (v) => (v || "").length < 15 || "Máximo 15 letras",
        ],
        email: [
          (v) => (v || "").length > 0 || "Email requerido",
          (v) => (v || "").length < 150 || "Máximo 150 letras",
          (v) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Email Invalido.";
          },
        ],
        attached: [
          (v) => {
            if (v) {
              return true;
            } else {
              return "Campo requerido";
            }
          },
          (v) =>
            !v ||
            v.size < 100000000 ||
            "No puede Subir Archivos mayor a 10 GB!",
        ],
        hours: [(v) => !!v || "Campo Requerido"],
      },
      steps: [
        {
          id: 1,
          name: "Registro de la Estrategia de Capacidades de Ciencia, Tecnología e Innovación",
        },
        { id: 2, name: "Docente que lidera la estrategia" },
        { id: 3, name: "Información general de la estrategia" },
      ],
      lastStep: 3,
      strategies: [
        {
          name: "Grupos de Estudio",
          description:
            "<b>Grupos de Estudio:</b> Investigación desde el aula alrededor de los núcleos problémicos de los programas académicos.",
        },
        {
          name: "Grupos de Trabajo",
          description:
            "<b>Grupos de Trabajo:</b> Investigación formativa y formación en investigación en colaboración y relacionamiento con actores externos nacionales e internacionales",
        },
        {
          name: "Colectivos Académicos",
          description:
            "<b>Colectivos Académicos:</b> Investigación formativa desde los contextos sociales en cooperación con actores del estado o la sociedad civil o sector comunitario o el sector productivo",
        },
        {
          name: "Semilleros de Investigación",
          description:
            "<b>Semilleros de Investigación:</b> Investigación formativa para promover la cultura investigativa de manera extracurricular. Primer peldaño de una trayectoria profesional como investigador",
        },
      ],
      researchHotbeds: [],
      sectionals: [],
      actionFields: [],
      investigationLines: [],
      investigationGroups: [],
      subjects: [],
      arrayProducts: [],
      productTypes: [],
      products: [],
      programs: [],
      sectionalPrograms: [],
      faculties: [],
      sectionalFaculties: [],
      divisions: [],
      sectionalDivisions: [],
      //Agregamos el periodo
      periods: [],
    };
  },
  computed: {
    formTitle() {
      return !this.editing
        ? "Nueva Estrategia de Formación"
        : "Editar Estrategia de Formación";
    },
    viewMode() {
      return !this.editing ? "Guardar" : "Guardar";
    },
    hintStrategy() {
      try {
        var _this2 = this;
        var strategy = this.strategies.find(
          (e) => e.name == _this2.itemEdited.strategy
        );
        return strategy.description;
      } catch {
        return "Por Favor Seleccione la Estrategia de Formación";
      }
    },
  },

  watch: {
    menuDate(val) {
      val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
    },
    "itemEdited.strategy"(val) {
      if (this.itemEdited.strategy == "Grupos de Estudio") {
        this.getNameStrategy =
          "Nombre del Grupo de Estudio implementada en la Estrategia curricular del programa académico";
      } else if (this.itemEdited.strategy == "Grupos de Trabajo") {
        this.getNameStrategy = "Nombre de Grupo de Trabajo";
      }
      if (this.itemEdited.strategy == "Colectivos Académicos") {
        this.getNameStrategy = "Nombre del Colectivo Académico";
        this.getProblem = "Problema Social";
      } else {
        this.getProblem = "Núcleo Problémico";
      }
      this.steps = [
        {
          id: 1,
          name: "Registro de la Estrategia de Capacidades de Ciencia, Tecnología e Innovación",
        },
        { id: 2, name: "Docente que lidera la estrategia" },
        { id: 3, name: "Información general de la estrategia" },
      ];
      if (val == "Grupos de Estudio" || val == "Grupos de Trabajo")
        this.steps = [
          ...this.steps,
          {
            id: 4,
            name: "Información de otros Docentes que participan en el desarrollo de la estrategia",
          },
          {
            id: 5,
            name: "Información de egresados que participan en el desarrollo de la estrategia",
          },
          {
            id: 6,
            name: "Información de estudiantes que participan en el desarrollo de la estrategia",
          },
        ];
      if (val == "Colectivos Académicos")
        this.steps = [
          ...this.steps,
          {
            id: 4,
            name: "Información de otros Docentes que participan en el desarrollo de la estrategia",
          },
          {
            id: 5,
            name: "Información de egresados que participan en el desarrollo de la estrategia",
          },
          {
            id: 6,
            name: "Información de estudiantes que participan en el desarrollo de la estrategia",
          },
          {
            id: 7,
            name: "Información de actores vinculados con el sector productivo",
          },
          { id: 8, name: "Información de actores vinculados con el Estado" },
          { id: 9, name: "Información de actores de la sociedad civil" },
        ];
      if (val == "Semilleros de Investigación")
        this.steps = [
          ...this.steps,
          {
            id: 4,
            name: "Información de otros Docentes que participan en el desarrollo de la estrategia",
          },
          {
            id: 6,
            name: "Información de estudiantes que participan en el desarrollo de la estrategia",
          },
        ];
      this.steps = [
        ...this.steps,
        {
          id: 10,
          name: "Productos que se esperan obtener con el desarrollo de la estrategia",
        },
        { id: 11, name: "Carta de Aprobación" },
      ];
      this.lastStep = 11;
      this.getProducts();
    },
    "itemEdited.sectional_id"(val) {
      this.itemEdited.leader.sectional_id = this.editing
        ? this.itemEdited.leader.sectional_id
        : val;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getSectionals();
      this.getActionFields();
      this.getPrograms();
      this.getDivisions();
      this.getFaculties();
      this.getSubjects();
      this.getPeriods(); //llamamos los periodos
    },

    nextStep(key, step) {
      this.e1 = step.id == this.lastStep ? 1 : key + 2;
      var id = step.id == this.lastStep ? 1 : step.id;
      toScroll("step" + id);
    },

    async change() {
      this.dialog = !this.dialog;
      Object.assign(this.itemEdited, this.strategyEdited);
      await this.getGroups();
      await this.getInvestigationLines();
    },

    saveDate(date) {
      this.menuDate = false;
      //this.$refs.menu1.save(date)
    },

    close() {
      this.$root
        .$confirm(
          "Cancelar Registro Estrategia",
          "Realmente desea Cancelar la edición de la Estrategia de formación?, NO será guardados los cambios",
          { color: "red" }
        )
        .then((res) => {
          if (res) {
            this.noConfirmClose();
            this.initializeContainer();
            this.$root.$alert({
              text: "No se Guardó...",
              color: "warning",
              timeout: 1000,
            });
          }
        });
    },

    noConfirmClose() {
      setTimeout(() => {
        this.change();
        //Object.assign(this.itemEdited, this.strategyEdited)
      }, 300);
    },

    initializeContainer() {
      this.$emit("initialize");
    },

    async getFile(item) {
      var params = { id: this.itemEdited.id, file: item };
      var name =
        removeAccents(item.id + "." + item.name ? item.name : item.strategy) +
        ".pdf";
      this.$root.$loadApp(true);
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/strategy_file",
          { responseType: "blob", params }
        );
        createLinkFile(response, name);
        this.$root.$alert({
          text: "Archivo Generado con Éxito",
          color: "success",
          timeout: 1000,
        });
      } catch (err) {
        console.error(err);
        this.$root.$alert({
          text: "Error al Descargar el Archivo",
          color: "error",
          timeout: 1000,
        });
      }
      this.$root.$loadApp(false);
    },

    async getLeader() {
      this.$root.$loadApp(true);
      await this.$http
        .get(URL_APP + "/api/siiicca/get_leader", {
          params: {
            identification: this.itemEdited.leader.third.identification,
          },
        })
        .then(async (response) => {
          if (response.data.third)
            Object.assign(this.itemEdited.leader.third, response.data.third);
          if (response.data.investigation_group) {
            this.itemEdited.leader.investigation_group_id =
              response.data.investigation_group.investigation_group_id;
            this.getInvestigationLines();
          }
        })
        .catch((err) => {
          console.error(err);
        });
      this.$root.$loadApp(false);
    },

    delFile(file) {
      var _this2 = this;
      this.$root
        .$confirm(
          "Eliminar Archivo",
          "Realmente desea Eliminar este Archivo?",
          { color: "red" }
        )
        .then((res) => {
          if (res) {
            //attached 1
            if (file == "approvement")
              _this2.itemEdited.approvement_letter = null;
            else _this2.itemEdited.final_report = null;
          } else {
            this.$root.$alert({
              text: "Acción Cancelada",
              color: "warning",
              timeout: 1000,
            });
          }
        });
    },

    async getSectionals() {
      try {
        var response = await this.$http.get(URL_APP + "/api/selsectionals");
        this.sectionals = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    //Agregando el método para obtener los periodos.
    async getPeriods() {
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/selstrategy_periods"
        );
        this.periods = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    async getActionFields() {
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/selaction_fields"
        );
        this.actionFields = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    async getProducts() {
      try {
        if (this.itemEdited.strategy) {
          var response = await this.$http.get(
            URL_APP + "/api/siiicca/selstrategy_products",
            { params: { strategy: this.itemEdited.strategy } }
          );
          this.products = response.data;
          console.log(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getPrograms() {
      this.programs = [];
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/get_programs"
        );
        this.programs = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    async getDivisions() {
      this.divisions = [];
      try {
        var response = await this.$http.get(URL_APP + "/api/seldivisions");
        this.divisions = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    async getFaculties() {
      this.faculties = [];
      try {
        var response = await this.$http.get(URL_APP + "/api/selfaculties");
        this.faculties = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    /*async getInvestigationGroups(){
      this.investigationGroups = []
      try{
        var response = await this.$http.get(URL_APP+'/api/siiicca/sel_invest_group')
        this.investigationGroups = response.data
      }catch(err){
        console.error(err)
      }
    },

    async getInvestigationLines () {
      try{
        var response = await this.$http.get(URL_APP+'/api/siiicca/selinvestigation_lines')
        this.investigationLines = response.data
      }catch(err){
        console.error(err)
      }
    },*/

    getGroups() {
      this.investigationGroups = [];
      this.sectionalPrograms = [];
      this.sectionalFaculties = [];
      this.sectionalDivisions = [];
      var _this2 = this;
      var itemFind = this.sectionals.find(
        (e) => e.id == _this2.itemEdited.sectional_id
      );
      this.investigationGroups = itemFind ? itemFind.groups : [];
      this.researchHotbeds = itemFind ? itemFind.research_hotbeds : [];
      this.sectionalFaculties = itemFind ? itemFind.faculties : [];
      this.sectionalDivisions = itemFind ? itemFind.divisions : [];
      this.sectionalPrograms = itemFind ? itemFind.programs : [];
    },

    getInvestigationLines() {
      var _this2 = this;
      this.investigationLines = [];
      var itemFind = this.investigationGroups.find(
        (e) => e.id == _this2.itemEdited.leader.investigation_group_id
      );
      this.investigationLines = itemFind ? itemFind.investigation_lines : [];
      console.log(this.investigationGroups);
      console.log(this.itemEdited.leader.investigation_group_id);
      console.log(this.investigationLines);
    },

    async getSubjects() {
      this.subjects = [];
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/selsubjects"
        );
        this.subjects = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    async save() {
      await this.$refs.form1.validate();
      //Añadir el Líder a los Actores
      var indexLeader = -1;
      var _this2 = this;
      this.itemEdited.leader.type = "Líder";
      if (
        !this.itemEdited.leader.third ||
        !this.itemEdited.leader.third.identification
      )
        this.$root.$alert({
          text: "Debe llenar los datos del Docente Líder",
          color: "error",
          timeout: 2000,
        });
      else if (this.itemEdited.activities.length < 1)
        this.$root.$alert({
          text: "Debe agregar al menos 1 actividad",
          color: "error",
          timeout: 2000,
        });
      else if (this.itemEdited.products.length < 1)
        this.$root.$alert({
          text: "Debe agregar al menos 1 producto",
          color: "error",
          timeout: 2000,
        });
      else if (!this.valid)
        this.$root.$alert({
          text: "Debe llenar los campos obligatorios",
          color: "error",
          timeout: 2000,
        });
      else {
        indexLeader = await this.itemEdited.actors.findIndex(
          (actor) =>
            actor.third &&
            actor.third.identification ==
              _this2.itemEdited.leader.third.identification
        );
        indexLeader > -1
          ? Object.assign(
              this.itemEdited.actors[indexLeader],
              this.itemEdited.leader
            )
          : this.itemEdited.actors.push(this.itemEdited.leader);
        const formData = objectToFormData(this.itemEdited);
        this.$root.$loadApp(true);
        await this.$http
          .post(URL_APP + "/api/siiicca/strategies", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.error) {
              this.$root.$alert({
                text: response.data.error,
                color: "error",
                timeout: 2000,
              });
            } else {
              this.initializeContainer();
              this.noConfirmClose();
              this.$root.$alert({
                text: "Guardado Correctamente",
                color: "success",
                timeout: 1000,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$root.$alert({
                text: error.response.data.message,
                color: "error",
                timeout: 5000,
              });
            } else {
              this.$root.$alert({
                text: "No se pudo Guardar la Estrategia, por favor Revise",
                color: "error",
                timeout: 5000,
              });
            }
          });
        this.$root.$loadApp(false);
      }
    },
  },
};
</script>

<template>
  <v-app id="inspire" class="back">
    <template v-if="!currentUser">
      <app-toolbar :show_drawer="drawer" :drawer.sync="drawer" />
      <v-main>
        <router-view />
      </v-main>
    </template>
    <template v-else>
      <app-toolbar :show_drawer="drawer" :drawer.sync="drawer" />
      <app-drawer
        class="app--drawer"
        :drawer="drawer"
        @drawerStatus="drawer = $event"
        @changePasswd="changePasswd"
      />
      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
        <ChangePass :drawer="drawpasswd" @changePasswd="changePasswd" />
      </v-main>
    </template>
    <v-footer inset app>
      <v-spacer />
      <div>&copy; Universidad Santo Tomás - {{ new Date().getFullYear() }} || Versión: {{ version }}</div>
    </v-footer>
    <SnackbarAlert ref="alert" />
    <Confirm ref="confirm" />
    <LoadApp ref="loadApp" />
    <!--<ExtendSession ref="extSession" ></ExtendSession>-->
    <!-- -->
  </v-app>
</template>

<script>
import AppDrawer from "./components/AppDrawer";
import AppToolbar from "./components/AppToolbar";
import AppEvents from "./util/event";
import ChangePass from "./components/ChangePass";
import Confirm from "./components/Confirm";
import SnackbarAlert from "./components/SnackbarAlert";
import LoadApp from "./components/LoadApp";
import { refresh } from "./helpers/auth.js";
import { version } from "../package.json";

export default {
  name: "App",
  components: {
    Confirm,
    ChangePass,
    SnackbarAlert,
    LoadApp,
    AppDrawer,
    //ExtendSession,
    AppToolbar, //,
  },
  data() {
    return {
      drawer: false,
      overlay: false,
      drawpasswd: false,
      timeToken: null,
      timeExtend: 0,
      restSeconds: 60,
      version: null,

    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  watch: {
    drawer() {
      if (this.currentUser.menus.length == 0 && this.drawer) {
        this.drawer = false;
      }
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$alert = this.$refs.alert.open;
    this.$root.$loadApp = this.$refs.loadApp.change;
    //this.$root.$extSession = this.$refs.extSession.open
    //Revisar si ya expiró el token
    this.inspectToken();
    this.version = version

  },
  created() {
    AppEvents.forEach((evnt) => {
      this.$on(evnt.name, evnt.callback);
    });
    window.getApp = this;
  },
  methods: {
    changePasswd(value) {
      this.drawpasswd = value;
    },

    createTimeToken() {
      //Calcular cuanto queda de tiempo para mostrar la extención de sesión (en segundos)
      this.timeExtend = Math.round(
        this.$store.getters.currentUser.token.expire - new Date() / 1000
      );
      this.timeExtend = Math.round((this.timeExtend - 70) * 1000); //Pasarlo a Milisegundos
      if (this.timeExtend <= 1) {
        clearTimeout(this.timeToken);
        this.$root.$loadApp("false");
        this.$store.commit("logout");
        this.$router.push({ path: "/" });
      } else {
        this.timeToken = setTimeout(this.refreshToken, this.timeExtend);
      }
    },

    refreshToken() {
      clearTimeout(this.timeToken);
      //Extender Session
      this.restSeconds = Math.round(
        this.$store.getters.currentUser.token.expire - new Date() / 1000 - 5
      ); //Segundos - 5 chance
      if (this.restSeconds <= 1) {
        this.$root.$loadApp("false");
        this.$router.push({ path: "/" });
        this.$store.commit("logout");
        this.$root.$alert({
          text: "Sesión Finalizada, Ingrese Nuevamente",
          color: "error",
          timeout: 2000,
        });
      } else {
        this.$root.$extSession(this.restSeconds).then((res) => {
          if (res) {
            //Realiza extensión de Sesión
            refresh()
              .then((res) => {
                this.$root.$alert({
                  text: "Se Extiende la Sesión ...",
                  color: "success",
                  timeout: 1000,
                });
                this.$store.commit("loginSuccess", res);
                this.createTimeToken();
              })
              .catch((err) => {
                console.log(err);
                this.$router.push({ path: "/" });
                this.$store.commit("logout");
                this.$root.$alert({
                  text: "No se pudo extender la sesión, Ingrese Nuevamente",
                  color: "error",
                  timeout: 2000,
                });
              });
          } else {
            //Hacer el Logout
            this.$root.$loadApp("false");
            this.$router.push({ path: "/" });
            this.$store.commit("logout");
          }
        });
      }
    },

    inspectToken() {
      const user = this.$store.getters.currentUser;
      if (user) {
        this.createTimeToken();
      }
    },
  },
};
</script>
<style scoped lang="css">
.back {
  background-image: url("./assets/Login.jpg") !important; /* The image used */
  background-color: #cccccc !important; /* Used if the image is unavailable */
  background-position: center !important; /* Center the image */
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-size: cover !important; /* Resize the background image to cover the entire container */
}
#inspire {
  background: none;
}
</style>

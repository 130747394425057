  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allRoles"
        :search="search"
        :loading="loading"
        sort-by="name"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Perfiles</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-toolbar dense color="primary" dark>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  <v-spacer/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                        <v-icon>fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Cerrar</span>
                  </v-tooltip>
                </v-toolbar>

                <v-card-text>
                  <v-form v-model="valid" ref="roles" @submit.prevent="save">
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.name" label="Nombre"
                          :rules="rules.name" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.description" label="Descripcion"
                          :rules="rules.description"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary" outlined @click="save" :disabled="loading" :loading="loading">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editPermission(item)" v-on="on" class="mr-1 my-1" color="orange lighten-1">fa-key</v-icon>
            </template>
            <span>Permisos</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog2" scrollable max-width="600px" persistent>
        <v-card>
          <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Permisos del Perfil {{editedItem.name}}</v-toolbar-title>
            <v-spacer/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                  <v-icon>fa-times</v-icon>
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <v-text-field
              v-model="search2"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="editedItem.permissions"
              :headers="headerPermissions"
              :items="allPermissions"
              item-key="id"
              :search="search2"
              show-select
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'fa-angle-double-left',
                lastIcon: 'fa-angle-double-right',
                prevIcon: 'fa-angle-left',
                nextIcon: 'fa-angle-right'
              }"
            >
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" outlined @click="close">Cerrar</v-btn>
            <v-btn color="primary" outlined @click="save" :loading="loading" :disabled="loading">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  export default{
    data() {
      return {
        dialog: false,
        dialog2: false,
        search: '',
        search2: '',
        rules: {
            name: [
                v => !!v || 'Campo requerido',
                v => v.length >= 5 || 'Mínimo 5 caracteres',
              ],
            description: [
                v => !!v || 'Campo requerido',
                v => v.length >= 5 || 'Mínimo 5 caracteres',
              ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Descripcion', value: 'description' },
            { text: 'Actions', value: 'action', sortable: false },
        ],
        headerPermissions: [
            { text: 'Permiso', value: 'description' },
        ],
        typesPerfil: [],
        editedIndex: -1,
        editedItem: {id: 0, name: '', description: '', permissions:[]},
        defaultItem: {id: 0, name: '', description: '', permissions:[]},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allRoles','allPermissions','loading']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getRoles','deleteRole','saveRole', 'updateRole',
            'chgLoad','getPermissions']),
      async initialize () {
        this.chgLoad(true)
        this.getPermissions()
        await this.getRoles()
        this.chgLoad(false)
      },

      editItem (item) {
        this.editedIndex = this.allRoles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        this.$root.$confirm("Eliminar Perfil", 'Esta seguro que desea eliminar el registro ?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteRole(item)
              this.$root.$alert({text:msg.message,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      },

      editPermission (item) {
        this.editedIndex = this.allRoles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog2 = true
      },

      close () {
        this.dialog = false
        this.dialog2 = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.permissions = []
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        if(this.$refs.roles)
          this.$refs.roles.validate()
        else
          this.valid = true
        if (this.valid){
          this.chgLoad(true)
          try{
            var msg = ""
            if (this.editedItem.id){
              msg = await this.updateRole(this.editedItem)
            }else{
              msg = await this.saveRole(this.editedItem)
            }
            this.$root.$alert({text:msg.message,color:"success",timeout:2000})
            this.close()
          }catch(error){
            this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Usuario",color:"error",timeout:2000})
          }
          this.chgLoad(false)
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },

    }
  }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Estrategias de Formación")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"sort-by":"name","item-key":"id","items-per-page":_vm.itemsPerPage,"page":_vm.page,"show-select":_vm.path,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),[_c('v-icon',{staticClass:"mx-2"},[_vm._v("fa-filter")]),_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.filterPeriods,"item-text":"description","item-value":"id","label":"Filtrar por periodo"},on:{"change":_vm.filterPeriod},model:{value:(_vm.idFilterPeriod),callback:function ($$v) {_vm.idFilterPeriod=$$v},expression:"idFilterPeriod"}}),_c('span',[(_vm.idFilterPeriod > 0)?_c('v-icon',{attrs:{"small":""},on:{"click":_vm.resetFilter}},[_vm._v("fa-times-circle")]):_vm._e()],1)],(_vm.path)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"small":"","text":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.getExcel.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" fa-file-excel ")])],1)]}}],null,false,3534445359)},[_c('span',[_vm._v("Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.getAnexos.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" fa-file-download ")])],1)]}}],null,false,402036130)},[_c('span',[_vm._v("Anexos")])])]:_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.newStrategy.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" fa-plus ")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])])],2)]},proxy:true},{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(rol,indexRol){return _c('v-chip',{key:indexRol,attrs:{"color":"secondary","dark":""}},[_vm._v(" "+_vm._s(rol)+" ")])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.period_id === _vm.active_period)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" fa-pen ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(item.period_id === _vm.active_period)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" fa-trash ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"orange lighten-1"},on:{"click":function($event){return _vm.pdfItem(item)}}},on),[_vm._v(" fa-file-pdf ")])]}}],null,true)},[_c('span',[_vm._v("Generar PDF")])]),(item.state == 'Activo' &&
            item.period_id != _vm.active_period &&
            !item.expiration
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.upgradePeriod(item)}}},on),[_vm._v(" fa-history ")])]}}],null,true)},[_c('span',[_vm._v("Actualizar periodo")])]):_vm._e(),(item.expiration &&
            !(item.state == 'Vencido' || item.state == 'Finalizado')
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"},on:{"click":function($event){return _vm.expirationItem(item)}}},on),[_vm._v(" fa-ban ")])]}}],null,true)},[_c('span',[_vm._v("Vencer ")])]):_vm._e(),(item.state == 'Activo' && item.period_id === _vm.active_period)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.finishItem(item)}}},on),[_vm._v(" fa-clipboard-list ")])]}}],null,true)},[_c('span',[_vm._v("Informe final")])]):_vm._e()]}},{key:"item.faculties",fn:function(ref){
            var item = ref.item;
return _vm._l((item.faculties),function(faculty,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(faculty.name)+" "),(index < item.faculties.length - 1)?_c('span',[_vm._v(" - ")]):_vm._e()])})}},{key:"no-data",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green","dark":""},on:{"click":function($event){return _vm.initialize()}}},on),[_c('v-icon',_vm._g({attrs:{"dark":""}},on),[_vm._v(" fa-sync-alt ")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])])]},proxy:true},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('span',{staticClass:"grey--text"},[_vm._v("Items por página")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("fa-angle-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('span',{staticClass:"mr-4 grey--text"},[_vm._v(" Página "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","x-small":"","dark":"","color":"blue darken-3"},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("fa-angle-left")])],1),_c('v-btn',{staticClass:"ml-1 mr-4",attrs:{"fab":"","x-small":"","dark":"","color":"blue darken-3"},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("fa-angle-right")])],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('FormStrategy',{ref:"strategy",attrs:{"strategy-edited":_vm.strategyEdited,"editing":_vm.editing},on:{"initialize":_vm.initialize}}),_c('FinishStrategy',{attrs:{"dialog":_vm.dialogFinish,"strategy-edited":_vm.strategyEdited},on:{"close":_vm.close,"initialize":_vm.initialize}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
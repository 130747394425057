<template>
  <v-card
    elevation="1"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-1 mx-1 mt-1"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'fa-angle-double-left',
        lastIcon: 'fa-angle-double-right',
        prevIcon: 'fa-angle-left',
        nextIcon: 'fa-angle-right'
      }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>Periodo de Estrategia</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="dialog = true"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!--Formulario de Grabación-->
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar
          dense
          color="primary"
          dark
        >
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                text
                dark
                v-on="{ ...tooltip }"
                @click="cancel()"
              >
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>          
        </v-toolbar>
        <v-card-text color="white">
          <v-form
            ref="form1"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  label="* Descripción"
                  required
                  :rules="rules.description"
                /> 
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  v-model="dialogStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.date_start"
                      label="Fecha de Inicio"
                      prepend-icon="fa-calendar-alt"
                      :rules="rules.date_start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.date_start"
                    @input="dialogStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  v-model="dialogEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.date_end"
                      label="Fecha Final"
                      prepend-icon="fa-calendar-alt"
                      :rules="rules.date_end"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.date_end"
                    @input="dialogEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="save()"
          >
            Guardar             
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="cancel()"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { URL_APP } from "../../util/constants";
export default {
  name:'Period',
  data(){
    return{
      headers:[
				{ text: 'Descripción', value: 'description' },
        { text: 'Inicio', value: 'date_start' },
        { text: 'Fin', value: 'date_end' },
        { text: 'Estado', value: 'state' },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
      valid: true,
      dialogEndDate: false,
      dialogStartDate: false,
      dialog: false,
      items:[],
      editing: false,
      editedItem: {},
      dates:[],
      loading: false,
      rules: {
        description: [
          v => !!v || 'Campo Requerido',
        ],
        period: [
          v => !!v || 'Campo Requerido',
        ],
        date_start: [
          v => !!v || 'Campo Requerido',
        ],
        date_end: [
          v => !!v || 'Campo Requerido',
        ],
      },
    }
  },
  computed:{
    formTitle(){
      return this.editing ? "Edición de Periodo" : "Nuevo Periodo"
    },
  },
  mounted(){
    this.initialize()
  },
  methods:{
    async initialize(){
      try{
        this.loading = true
        var response = await this.$http.get(URL_APP+'/api/siiicca/strategy_periods')
        this.items = response.data
      }catch(err){
        console.error(err)
        this.$root.$alert({text:"Error Obteniendo los Periodos",color:"error",timeout:2000})
      }
      this.loading = false
    },

    editItem (item) {
      this.editing = true
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    async deleteItem (item) {
      this.$root.$confirm("Eliminar Periodo", 'Esta seguro que desea eliminar el periodo ?', { color: 'red' })
      .then(async (res) => {
        if(res){
          await this.$http.delete(URL_APP+'/api/siiicca/strategy_periods/'+item.id).then(response => {
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
          }).catch(error => {
            console.error(error)
            this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
          });
        }else{
          this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
        }
      })
    },

    cancel(){
      this.dialog = false
      this.editing = false
      this.editedItem = {}
    },

    async save(){
      this.$refs.form1.validate()
      if(this.valid){
        try{
          var response = this.editing? await this.$http.put(URL_APP+'/api/siiicca/strategy_periods', this.editedItem) 
              : await this.$http.post(URL_APP+'/api/siiicca/strategy_periods', this.editedItem)
          this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          this.initialize()
          this.cancel()
        }catch(err){
          console.error(err)
          this.$root.$alert({text:"Error al guardar el Periodo",color:"error",timeout:2000})
        }
      }else{
        this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
      }
    },
  }
}
</script>

<style>

</style>
  <template>
    <div>
      <v-dialog
        v-model="dialog"
        :max-width="simpleForm?'400px':'1000px'"
        scrollable
        persistent
      >
        <v-card>
          <v-toolbar dense color="primary" dark>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                  <v-icon>fa-times</v-icon>
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="save()"
              ref="formProgram_"
              v-model="valid"
            >
              <v-container grid-list-md>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="* Nombre"
                      :rules="rules.name"
                    />
                  </v-col>
                  <v-col cols="12" v-if="!simpleForm">
                    <v-text-field
                      v-model="editedItem.codesac"
                      label="Codigo SAC"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" v-if="!simpleForm">
                    <v-switch
                      v-model="editedItem.degree_work"
                      label="Habilitar Trabajos de grado"
                    />
                  </v-col>
                  <v-col cols="12" v-if="!simpleForm">
                    <v-switch
                      v-model="editedItem.active"
                      label="Activo"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary darken-1"
              outlined
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn :loading="loading" :disabled="loading"
              color="primary darken-1"
              outlined
              @click="save"
            >
              {{ viewMode }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
  export default{
    name: 'FormProgram',
    props : {
      simpleForm: { type: Boolean, default:false}
    },
    data() {
      return {
        dialog: false,
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 5 || 'Mínimo 5 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
        },
        editedItem: {id: 0, codesac:'', name: '', type:'', degree_work:false, active: true},
        defaultItem: {id: 0, codesac:'', name: '', type:'', degree_work:false, active: true},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading']),
      formTitle () {
        return this.editedItem.id == 0 ? 'Nuevo Programa' : 'Editar Programa'
      },
      viewMode () {
        return this.editedItem.id == 0 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    methods: {
      ...mapActions(['saveProgram', 'updateProgram','chgLoad']),

      newItem () {
        this.dialog = true
      },

      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        this.$refs.formProgram_.validate()
        if(!this.loading){
          if (this.valid){
            this.chgLoad(true)
            try{
              var msg = ""
              if (this.editedItem.id){
                msg = await this.updateProgram(this.editedItem)
              }else{
                msg = await this.saveProgram(this.editedItem)
              }
              this.$root.$alert({text:msg.message,color:"success",timeout:2000})
              this.close()
            }catch(error){
              this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Programa",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>

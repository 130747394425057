import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/users/users"

const state = {
  users: []
}

const getters = {
  allUsers: state => state.users
}

const actions = {

  async getUsers({commit}){
    return new Promise(function(resolve, reject){
      commit('setUsers', [])
      axios.get(URL_).then(res=>{
        commit('setUsers', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveUser({commit},user){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, user).then(res=>{
        commit('newUser', res.data.user)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateUser({commit},user){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, user).then(res=>{
        //console.log(res.data)
        commit('updUser', res.data.user)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteUser({commit}, user){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${user.id}`).then(res=>{
        commit('delUser', user)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setUsers : (state, users) => state.users = users,
  newUser  : (state, user) => state.users.unshift(user),
  updUser  : (state, user) => {
    const index = state.users.findIndex(u => u.id === user.id)
    if (index !== -1) state.users.splice(index, 1, user)
  },
  delUser  : (state, user) => state.users = state.users.filter(u => u.id !== user.id)
}

export default{
  state, getters, actions, mutations
}
<template>
  <v-container
    fluid
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12 pa-3">
          <v-card-text>
            <div class="layout column align-center">
              <img
                class="primary"
                src="../../assets/santoto.png"
                alt="USTAB"
                width="90%"
              >
              <h4 class="flex my-4 primary--text">
                Recupera tu contraseña
              </h4>
            </div>                
            <v-form
              v-if="!formRecover.user"
              ref="recover"
              v-model="valid"
              @submit.prevent="false"
            >
              <v-text-field
                append-icon="fa-at"
                v-model="form.email"
                name="login"
                label="Correo Electrónico"
                type="text"
                :rules="rules.emailRules"
                required
                autofocus
                @keyup.enter="nextMail"
              />
              <!--{{-- CAPTCHA --}}-->
              <vue-recaptcha
                ref="invisibleRecaptcha"
                :sitekey="sitekey"
                @verify="onVerify"
                @expired="onExpired"
              />
            </v-form>
            <v-form
              v-else
              ref="recover"
              v-model="valid"
              @submit.prevent="false"
            >
              <span>Se encontraron los Siguientes Correos de recuperación</span>
              <v-radio-group v-model="formRecover.mail">
                <v-radio
                  v-for="mail in userRecover.mails"
                  :key="mail"
                  :label="mail"
                  :value="mail"
                />
              </v-radio-group>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container
              grid-list-md
              text-center
            >
              <v-row wrap>
                <v-col
                  cols="6"
                  class="my-2"
                >
                  <v-btn
                    text
                    color="orange darken-1"
                    to="/login"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col
                  v-if="!formRecover.user"
                  cols="6"
                  class="my-2"
                >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="disableButton"
                    @click="nextMail"
                  >
                    Siguiente
                  </v-btn>
                </v-col>
                <v-col
                  v-else
                  cols="6"
                  class="my-2"
                >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="recoverMail"
                  >
                    Enviar Correo
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import {ofuscaMail} from '@/js/util';
import { URL_APP } from "../../util/constants";
import VueRecaptcha from 'vue-recaptcha'
import * as constants from '../../util/constants'
export default {
    name: 'Recover',
    components: {VueRecaptcha},
    props: {
        source: String
    },
    data() {
        return {
            sitekey: constants.KEY_CLIENT, 
            loading: false,
            disabledSubmit: true,
            form:{
                email: '',
                captcha: ''
            },
            formRecover:{
                user: '',
                k: constants.KEY_CLIENT,
                mail: ''
            },
            userRecover:[],
            radioMail: null,
            error: null,
            rules: {
              min: v => v.length >= 8 || 'Mínimo 8 caracteres',
              emailRules: [
                v => !!v || 'Correo Requerido',
                v => /.+@.+\..+/.test(v) || 'Correo Invalido',
              ],
            },
            valid: true,
        }
    },
    computed: {
        disableButton: function (){
            if(this.valid){
              if(this.form.captcha!=""){
                return false
              }else{
                return true
              }
            }else{
              return true
            }
        }
    },
    mounted() { 
      let recaptchaScript = document.createElement ('script'); 
      recaptchaScript.setAttribute('src', "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"); 
      document.head.appendChild(recaptchaScript); 
    },
    methods: {
        nextMail(){
            if (!this.disableButton) {
              this.loading = true;
              this.$http.post(URL_APP+'/api/password/findemail', this.form)
              .then((res)=>{
                  this.userRecover = res.data;
                  this.formRecover.user = res.data.user;
                  this.formRecover.mail = res.data.mails[0];
                  this.loading = false;
                  this.resetRecaptcha()
              })
              .catch((error)=>{
                console.log(error)
                this.$root.$alert({text:"No se encuentra cuente registrada",color:"error",timeout:2000})
                this.loading = false;
                this.resetRecaptcha()
              });
            }else{
              this.$root.$alert({text:'Por favor revise los Errores',color:"error",timeout:2000})
            }
        },
        recoverMail(){
          this.loading = true;
              this.$http.post(URL_APP+'/api/password/token', this.formRecover)
              .then((res)=>{
                  this.$root.$alert({text:res.data.message,color:"success",timeout:2000})
                  this.$router.push({path: '/login'});
                  this.loading = false;
              })
              .catch((error)=>{
                  this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
                  this.loading = false;
              });
        },
        onVerify: function (response) {
          this.form.captcha = response;
        },
        onExpired: function () {
          this.form.captcha = "";
        },
        resetRecaptcha () {
          this.$refs.invisibleRecaptcha.reset() // Direct call reset method
          this.onExpired()
        }
    }
}
</script>

  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allUsers"
        :search="search"
        :loading="loading"
        sort-by="name"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-toolbar dense color="primary" dark>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  <v-spacer/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                        <v-icon>fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Cerrar</span>
                  </v-tooltip>
                </v-toolbar>

                <v-card-text>
                  <v-form v-model="valid" ref="form1" @submit.prevent="save">
                    <v-container grid-list-md>
                      <v-row >
                        <v-col cols="12">
                          <v-select required
                            v-model="editedItem.id_sectional"
                            :items="sectionals"
                            :rules="rules.sectional_id"
                            item-text="name"
                            item-value="id"
                            label="* Seccional"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field type="number" v-model="editedItem.identity" label="Identidad"
                          :rules="rules.identity" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.name" label="Nombre"
                          :rules="rules.name" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.email" label="Correo Electrónico"
                          :rules="rules.email" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.roles"
                            :items="allRoles"
                            item-text="name"
                            item-value="name"
                            attach
                            chips
                            label="Roles"
                            multiple
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.password" label="Contraseña"
                          :rules="rules.password" type="password"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-switch v-model="editedItem.active" label="Estado"></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary" outlined @click="save" :disabled="loading" :loading="loading">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.roles="{ item }">
          <v-chip color="secondary" v-for="(rol, indexRol) in item.roles" :key="indexRol" dark>{{ rol.name }}</v-chip>
        </template>
        <template v-slot:item.active="props">
          {{stateItem(props.item.active)}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
        </template>
      </v-data-table>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
  export default{
    data() {
      return {
        dialog: false,
        search: '',
        selected: [],
        rules: {
            name: [
              v => !!v || 'Campo requerido',
              v => v.length >= 5 || 'Mínimo 5 caracteres',
            ],
            email: [
              v => !!v || 'Campo Requerido'
            ],
            identity: [
              v => !!v || 'Campo Requerido'
            ],
            sectional_id: [
              v => !!v || 'Campo Requerido'
            ]
        },
        headers: [
            { text: 'Seccional', value: 'sectional.name' },
            { text: 'Identidad', value: 'identity' },
            { text: 'Nombre', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Roles', value: 'roles' },
            { text: 'Estado', value: 'active' },
            { text: 'Actions', value: 'action', sortable: false },
        ],
        sectionals: [],
        editedIndex: -1,
        editedItem: {id_sectional:'', id: null, identity: 0, name: '', email: '', roles: [], password: '', active: true},
        defaultItem: {id_sectional:'', id: null, identity: 0, name: '', email: '', roles: [], password: '', active: true},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allUsers','allRoles','allSectionals','loading']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getUsers','deleteUser','saveUser', 'updateUser',
            'getRoles','chgLoad','getSectionals']),

      async initialize () {
        this.chgLoad(true)
        this.getRoles()
        this.selSectionals()
        await this.getUsers()
        this.chgLoad(false)
      },

      //Taer las Seccionales
      async selSectionals () {
        await this.getSectionals()
        this.sectionals = this.allSectionals.filter(s => (s.active || s.id==this.editedItem.id_sectional))
      },

      editItem (item) {
        this.editedIndex = this.allUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      async deleteItem (item) {
        this.$root.$confirm("Eliminar Usuario", 'Esta seguro que desea eliminar el registro ?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteUser(item)
              this.$root.$alert({text:msg,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        this.$refs.form1.validate()
        if (this.valid){
          this.chgLoad(true)
          try{
            var msg = ""
            if (this.editedItem.id){
              msg = await this.updateUser(this.editedItem)
            }else{
              msg = await this.saveUser(this.editedItem)
            }
            this.$root.$alert({text:msg.message,color:"success",timeout:2000})
            this.close()
          }catch(error){
            this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Usuario",color:"error",timeout:2000})
          }
          this.chgLoad(false)
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="type_third=='Interno'? projectEdited.internal_investigators:projectEdited.external_investigators"
      item-key="third.id"
      sort-by="name"
      class="elevation-1 mx-1 mt-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>* Investigadores</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                x-small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="newItem()"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo Investigador</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="900px"
            persistent
          >
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <span>{{ formTitle }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="mx-2 my-2"
                      x-small
                      dark
                      color="secondary"
                      @click.stop="dialogHelp=true"
                      v-on="on"
                    >
                      fa-question
                    </v-icon>
                  </template>
                  <span>Ayuda</span>
                </v-tooltip>
                <v-spacer />
                <v-btn
                  text
                  @click="cancel"
                >
                  X
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-subheader>Datos de Investigador</v-subheader>
                <v-form
                  ref="form"
                  v-model="valid"
                  @change="edited=true"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="investEdited.type_investigator"
                          :items="typeInves"
                          item-value="id"
                          item-text="name" 
                          :rules="rules.type"
                          label="* Tipo de Investigador"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="investEdited.third.type_id"
                          :items="typeIdent"
                          item-value="id"
                          item-text="name" 
                          :rules="rules.type_id"
                          label="* Tipo Identificación"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.identification"
                          name="identification" 
                          label="* Identificación Investigador"
                          :rules="rules.identification" 
                          type="number"
                          @change="getInvestigator()"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.name"
                          name="name" 
                          label="* Nombre Investigador"
                          type="text"
                          :rules="rules.name"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.city"
                          name="city" 
                          label="* Ciudad de Expedición"
                          :rules="rules.city"
                          type="text"
                        />
                      </v-col>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.mobile"
                          name="mobile" 
                          label="* Celular"
                          :rules="rules.mobile"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.email"
                          name="email"
                          label="* Email" 
                          :rules="rules.email"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.training"
                          name="training" 
                          label="* Última Formación Académica"
                          :rules="rules.training"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <span>* Vinculo Laboral</span>
                        <v-radio-group
                          v-model="investEdited.link"
                          name="link"
                          row
                        >
                          <v-radio
                            label="Tiempo Completo"
                            value="completo"
                          />
                          <v-radio
                            label="Medio Tiempo"
                            value="medio"
                          />
                        </v-radio-group>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="investEdited.colciencias"
                          :items="typeColci"
                          item-value="id"
                          item-text="name"
                          name="colciencias" 
                          :rules="type_third=='Interno'?rules.colciencias:[]"
                          :label="type_third=='Interno'?'* Categoria Colciencias':'Categoria Colciencias'"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.cvlac"
                          name="cvlac" 
                          :rules="type_third=='Interno'?rules.cvlac:[]" 
                          :label="type_third=='Interno'?'* Enlace CVLAC':'Enlace CVLAC'"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.orcid"
                          name="orcid" 
                          :rules="type_third=='Interno'?rules.orcid:[]" 
                          :label="type_third=='Interno'?'* Enlace ORCID':'Enlace ORCID'"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.third.gooAcademico"
                          name="gooAcademico" 
                          :rules="type_third=='Interno'?rules.google:[]" 
                          :label="type_third=='Interno'?'* Enlace Google Académico':'Enlace Google Académico'"
                          type="text"
                          required
                        />
                      </v-col>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="investEdited.payroll_hours"
                          name="payroll_hours" 
                          label="* Horas Nómina al Mes"
                          :rules="rules.payroll_hours" 
                          type="number"
                          required
                        />
                      </v-col>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="investEdited.sectional_id"
                          :rules="rules.sectional_id" 
                          :items="sectionals"
                          item-value="id"
                          item-text="name"
                          text="name"
                          label="* Seccional"
                          placeholder="Seleccione la seccional del investigador"
                          required
                          @change="getGroups"
                        />
                      </v-col>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="investEdited.investigation_group_id"
                          :rules="rules.investigation_group_id" 
                          :items="groups"
                          item-value="id"
                          item-text="name"
                          text="name"
                          label="* Grupo de Investigación"
                          placeholder="Seleccione el grupo de investigación"
                          required
                        />
                      </v-col>
                      <v-col
                        v-if="type_third=='Interno'"
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="investEdited.faculty_id"
                          :rules="rules.faculty_id" 
                          :items="faculties"
                          item-value="id"
                          item-text="name"
                          text="name"
                          label="* Facultad"
                          placeholder="Seleccione la Facultad"
                          required
                        />
                      </v-col>
                      <template v-if="type_third=='Externo'">
                        <v-col cols="12">
                          <v-divider />
                          <v-subheader>Datos de Entidad</v-subheader>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.entity.nit"
                            name="nit"
                            label="Nit"
                            type="number"
                            required
                            @change="getEntity()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.entity.name"
                            name="name" 
                            label="* Nombre"
                            :rules="rules.name" 
                            type="text"
                            @change="getEntity()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.entity.representative"
                            name="representative" 
                            label="* Representante Legal"
                            :rules="rules.representative" 
                            type="text"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.interlocutor"
                            name="interlocutor" 
                            label="* Interlocutor"
                            :rules="rules.interlocutor" 
                            type="text"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.contact"
                            name="contact" 
                            label="* Telefono de Contacto"
                            :rules="rules.contact"
                            type="text"
                            counter="20"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.email"
                            name="email" 
                            label="* Email"
                            :rules="rules.email" 
                            type="text"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="investEdited.entity.web_page"
                            name="web_page" 
                            label="Página Web" 
                            type="text"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete
                            v-model="investEdited.entity.country_id"
                            :rules="rules.country_id" 
                            :items="countries"
                            item-value="id"
                            item-text="name"
                            text="name"
                            label="* País"
                            placeholder="Seleccione el País"
                            required
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="save"
                >
                  {{ viewMode }}
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="cancel()"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogHelp"
      max-width="500"
      @keydown.esc="dialogHelp = false"
    >
      <v-card>
        <v-system-bar
          dark
          color="secondary"
          dense
          flat
        >
          <span class="white--text">Ayuda</span>
          <v-spacer />
          <v-btn
            text
            small
            @click="dialogHelp = false"
          >
            X
          </v-btn>
        </v-system-bar>
        <v-card-text>
          <v-container fluid>
            <v-row
              justify="center"
              align-content="center"
              align="center"
            >
              Los Campos con Asterisco (*) son obligatorios<br>
              Los Campos Aparecen o desaparecen según el tipo de Investigador<br>
              Debe realizar el registro del Investigador principal, el Joven Investigador y el estudiande de Doctorado o Especialización en el primer grupo<br>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { fechaHoy } from '../../util/functions';
import { URL_APP } from "../../util/constants";

export default {
  name: "Investigator",
  props: {
    projectEdited: {
      type: Object,
      default: null
    },
    type_third:{
      type: String,
      default: "Interno"
    }
  },
  data() {
    return {
      editedIndex: -1,
      edited:true,
      menFechGrad: false,
      headers: [
          { text: 'Id', value: 'third.identification' },
          { text: 'Tipo', value: 'type_investigator' },
          { text: 'Nombre', value: 'third.name' },
          { text: 'Actions', value: 'action', sortable: false },
      ],
      valid: null,
      dialog: false,
      dialogHelp: false,
      typeIdent: [
        {id:'CC', name:'Cédula de Ciudadanía'}, 
        {id:'TI', name:'Tarjeta de Identidad'}, 
        {id:'CI', name:'Cédula de Identidad'}, 
        {id:'TP', name:'Tarjeta Pasaporte'}, 
        {id:'RC', name:'Registro Civil'}, 
        {id:'CE', name:'Cédula de Extranjería'}, 
      ],
      sectionals: [],
      groups:[],
      faculties:[],
      typeInves: [],
      countries: [],
      typeColci: [
        {id:'IS', name:'Investigador Senior'}, 
        {id:'I', name:'Investigador Asociado'}, 
        {id:'IJ', name:'Investigador Junior'}, 
        {id:'ED', name:'Estudiante de Doctorado'}, 
        {id:'EM', name:'Estudiante de Maestria o Especialidad Clínica'}, 
        {id:'JI', name:'Joven Investigador'}, 
        {id:'IPO', name:'Investigador del Programa Ondas'}, 
        {id:'EP', name:'Estudiante de Pregado'}, 
        {id:'IVD', name:'Integrante Vinculado con Doctorado'}, 
        {id:'IVM', name:'Integrante Vinculado con Maestria'}, 
        {id:'IVE', name:'Integrante Vinculado con Especialización'}, 
        {id:'IVP', name:'Integrante Vinculado con Pregado'}, 
        {id:'IV', name:'Integrante Vinculado'}, 
      ],
      investEdited: {
        id: 'n_',
        type_investigator: '',
        type_third: '',
        training: '',
        link: '',
        colciencias: '',
        payroll_hours: '',
        sectional_id: '',
        investigation_group_id: '',
        division_id: '',
        faculty_id: '',
        entity_id: '',
        interlocutor:'',
        email: '',
        contact: '',
        entity: {
          nit:0,
          name:'',
          representative:'',
          country_id:'',
        },
        third:{
          id:0,
          type_id: '',
          identification: '',
          name: '',
          city: '',
          mobile: '',
          email: '',
          cvlac: '',
          orcid: '',
          gooAcademico: '',
        },
      },
      defaultItem: {
        id: 'n_',
        type_investigator: '',
        type_third: '',
        training: '',
        link: '',
        colciencias: '',
        payroll_hours: '',
        sectional_id: '',
        investigation_group_id: '',
        division_id: '',
        faculty_id: '',
        entity_id: '',
        interlocutor:'',
        email: '',
        contact: '',
        entity: {
          nit:0,
          name:'',
          representative:'',
          country_id:'',
        },
        third:{
          id:0,
          type_id: '',
          identification: '',
          name: '',
          city: '',
          mobile: '',
          email: '',
          cvlac: '',
          orcid: '',
          gooAcademico: '',
        },
      },
      countNew:0,
      rules: {
          type: [v => (v || '').length > 0 || 'Campo requerido'],
          name: [v => (v || '').length > 0 || 'Nombre requerido',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          training: [
            v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 80 || 'Máximo 80 letras',
          ],
          faculty_id: [ v => !!v || 'Campo Requerido',  ],
          link: [v => (v || '').length > 0 || 'Campo requerido'],
          type_id: [v => (v || '').length > 0 || 'Tipo de Identificación requerido'],
          identification: [v => (v || '').length > 0 || 'Identificación requerido',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          city: [v => (v || '').length > 0 || 'Ciudad de Expedición requerido',
            v => v.length < 80 || 'Máximo 80 letras',
          ],
          mobile: [v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 20 || 'Máximo 20 ',
          ],
          email: [
            v => (v || '').length > 0 || 'Email requerido',
            v => v.length < 150 || 'Máximo 150 letras',
            v => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(v) || 'Email Invalido.'
            }
          ],
          colciencias: [v => (v || '').length > 0 || 'Campo requerido'],
          cvlac: [v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 300 || 'Máximo 300 letras',
          ],
          orcid: [v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 300 || 'Máximo 300 letras',
          ],
          google: [v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 300 || 'Máximo 300 letras',
          ],
          payroll_hours: [
            v => !!v || 'Campo Requerido',
          ],
          sectional_id: [ v => !!v || 'Campo Requerido',  ],
          investigation_group_id: [ v => !!v || 'Campo Requerido',  ],
          country_id: [ v => !!v || 'Campo Requerido',  ],
          representative: [v => (v || '').length > 0 || 'Campo requerido'],
          contact: [v => (v || '').length > 0 || 'Campo requerido', 
            v => (v || '').length <= 20 || 'Máximo 20 Caracteres'],
          interlocutor: [v => (v || '').length > 0 || 'Campo requerido',
            v => v.length < 150 || 'Máximo 150 letras',
          ],
      },
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Investigador ' : 'Editar Investigador '
    },
    viewMode () {
      return this.editedIndex === -1 ? 'Guardar' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {

    initialize () {
      this.getTypeInves();
      //Funcion para inicializar el Componente
        this.getSectionals()
        this.getCountries();
    },

    validInves(typeInv){
      var investigators = this.type_third == "Interno" ? this.projectEdited.internal_investigators: this.projectEdited.external_investigators
      //Recorre los grupos en busca de Investigadores
      investigators.forEach(function(investigator){
        if(investigator.type_investigator==typeInv){
          return true
        }
      })
      return false
    },

    getTypeInves() {
      //Tipos de Investigadores Según el tipo de Grupo de Investigación
      this.typeInves = []
      var existePrincipalL = false
      var investigators = this.type_third == "Interno" ? this.projectEdited.internal_investigators: this.projectEdited.external_investigators
      investigators.forEach(function (investigator) {
        if(investigator.type_investigator=="Principal"){
          existePrincipalL = true
        }
      })
      if(!existePrincipalL || this.investEdited.type_investigator=="Principal"){
        this.typeInves.push({id:'Principal', name:'Investigador Principal'})
      }
      this.typeInves.push({id:'Coinvestigador', name:'Coinvestigador'})
    },

    //Taer los Países
    getCountries () {
      let url = URL_APP+"/api/countries"
      this.$http.get(url)
      .then(res => {
        this.countries = res.data;
      });
    },

    //Taer las Seccionales
    getSectionals () {
      let url = URL_APP+"/api/selsectionals"
      this.$http.get(url)
      .then(res => {
        this.sectionals = res.data;
      });
    },

    getGroups(){
      this.groups = []
      this.faculties = []
      var _this2 = this
      this.sectionals.forEach(function(sectional){
        if(sectional.id == _this2.investEdited.sectional_id){
          _this2.groups = sectional.groups
          _this2.faculties = sectional.faculties
        }
      })
    },

    getEntity(){

    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.investEdited = Object.assign({}, this.defaultItem)
        this.investEdited.entity = Object.assign({}, this.defaultItem.entity)
        this.investEdited.third = Object.assign({}, this.defaultItem.third)
        this.editedIndex = -1
      }, 300)
    },

    deleteItem (item) {
      const index = this.type_third == "Interno" ? this.projectEdited.internal_investigators.indexOf(item) : this.projectEdited.external_investigators.indexOf(item)
      this.$root.$confirm('Eliminar Investigador', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.type_third == "Interno" ? this.projectEdited.internal_investigators.splice(index, 1): this.projectEdited.external_investigators.splice(index, 1)
          this.$root.$alert({text:"¡ Investigador Eliminado !",color:"success",timeout:1000})
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    cancel () {
      if(this.edited){
        var txtCancel = ""
        if(this.editedIndex > -1){
          txtCancel = "Cancelar Edición"
        }else{
          txtCancel = "Cancelar Adición"
        }
        this.$root.$confirm(txtCancel, 'Esta Seguro?, Podría Perder Los Datos editados', { color: 'warning' })
        .then((res) => {
          if(res){
            this.close()
            this.$root.$alert({text:"¡ Cancelado !",color:"success",timeout:1000})
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      }else{
        this.close()
      }
    },

    editItem (item) {
      this.editedIndex = this.type_third == "Interno" ? this.projectEdited.internal_investigators.indexOf(item) : this.projectEdited.external_investigators.indexOf(item)
      this.investEdited = Object.assign({}, item)
      this.getTypeInves()
      this.getGroups()
      this.dialog = true
    },

    newItem () {
      this.getTypeInves()
      this.investEdited = Object.assign({}, this.defaultItem)
      this.investEdited.entity = Object.assign({}, this.defaultItem.entity)
      this.investEdited.third = Object.assign({}, this.defaultItem.third)
      this.editedIndex = -1
      this.investEdited.id = 'n_'+this.countNew
      this.countNew ++
      this.dialog = true
    },
    getInvestigator () {
      this.$root.$loadApp(true)
      this.$http.get(URL_APP+"/api/siiicca/getinvestigator", {params: {"identification":this.investEdited.third.identification, "type":this.investEdited.type_investigator}}).then(response => {
        if (response.data.third.id) this.investEdited.third.id = response.data.third.id
        if (response.data.third.name) this.investEdited.third.name = response.data.third.name
        if (response.data.third.city) this.investEdited.third.city = response.data.third.city
        if (response.data.third.mobile) this.investEdited.third.mobile = response.data.third.mobile
        if (response.data.third.email) this.investEdited.third.email = response.data.third.email
        if (response.data.third.cvlac) this.investEdited.third.cvlac = response.data.third.cvlac
        if (response.data.third.orcid) this.investEdited.third.orcid = response.data.third.orcid
        if (response.data.third.gooAcademico) this.investEdited.third.gooAcademico = response.data.third.gooAcademico
        //
        if (response.data.third.training) this.investEdited.training = response.data.third.training
        if (response.data.third.link) this.investEdited.link = response.data.third.link
        if (response.data.third.colciencias) this.investEdited.colciencias = response.data.third.colciencias
        if (response.data.third.sectional_id) this.investEdited.sectional_id = response.data.third.sectional_id
        if (response.data.third.investigation_group_id) this.investEdited.investigation_group_id = response.data.third.investigation_group_id
        if (response.data.third.scale) this.investEdited.scale = response.data.third.scale
        if (response.data.third.division) this.investEdited.division = response.data.third.division
        if (response.data.third.faculty) this.investEdited.faculty = response.data.third.faculty

        //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
        this.$root.$loadApp(false)
      }).catch(error => {
        console.error(error)
        this.$root.$loadApp(false)
      });
    },

    fechaHoy,

    save() {
      this.investEdited.type_investigator = (this.type_third == "Interno")? this.investEdited.type_investigator:"Coinvestigador"
      this.$refs.form.validate()
      if(!this.valid){
        this.$root.$alert({text:"No se puede Guardar, por favor revise los errores",color:"error",timeout:2000})
      }else{
        //Añadimos El Investigador al Grupo
        this.$emit('addInvestigator',this.investEdited, this.editedIndex, this.type_third)
        this.close()
      }
    }
  }
}
</script>

<template>
  <v-container>
    <!--<div>
      <span class="body-2 mb-0">FORMATO PARA LA PRESENTACIÓN DE PROYECTOS DE INVESTIGACIÓN </span>
      <span class="subheading mb-0">Esta es una descripción breve del formato</span>
    </div>-->
    <v-stepper
      v-model="e1"
      vertical
    >
      <v-stepper-step
        step="1"
        editable
      >
        Datos Básicos
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-select
          v-model="projectEdited.announcement_id"
          autofocus
          :items="convocatorias"
          item-value="id"
          item-text="name" 
          :rules="rules.announcement_id"
          label="* Convocatoria"
          @change="getConvocatoria" 
        />
        <v-text-field
          v-model="projectEdited.title"
          name="title"
          label="* Titulo del Proyecto"
          type="text"
          :rules="rules.title"
          required
        />
        <v-select
          v-model="projectEdited.sectional_id"
          :items="sectionals" 
          :rules="rules.sectional"
          item-value="id"
          item-text="name"
          label="* Seccional Investigador Principal"
        />
        
        <v-btn
          class="mt-3"
          color="primary"
          @click="e1 = 2"
        >
          Siguiente
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        editable
      >
        Investigadores USTA
      </v-stepper-step>

      <v-stepper-content step="2">
        <!--Investigadores USTA-->
        <Investigator
          :project-edited="projectEdited"
          type_third="Interno"
          @addInvestigator="addInvestigator"
        />
        <div><b>Horas Nómina Total : </b> {{ totalHoras }} </div>

        <v-btn
          class="mt-3"
          color="primary"
          @click="e1 = 3"
        >
          Siguiente
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="3"
        editable
      >
        Aliados Externos
      </v-stepper-step>

      <v-stepper-content step="3">
        <!--Investigadores Externos-->
        <Investigator
          :project-edited="projectEdited"
          type_third="Externo"
          @addInvestigator="addInvestigator"
        />
          
        <v-btn
          class="mt-3"
          color="primary"
          @click="e1 = 4"
        >
          Siguiente
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="4"
        editable
      >
        Documentación requerida
      </v-stepper-step>
      <v-stepper-content step="4">
        <template v-for="(attached, index) in projectEdited.announcement.attacheds">
          {{ attached.name }}
          <v-file-input
            v-if="!projectEdited.attacheds[index].name"
            :key="'attached'+attached.id"
            v-model="projectEdited.attacheds[index].file"
            :rules="attached.required ? rules.attached: []"
            :accept="attached.accept"
            placeholder="Subir Archivo"
            label=""
            show-size
            prepend-icon="fa-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-card
            v-else
            :key="'card-attached'+attached.id"
            max-width="500"
            class="ml-1"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      icon
                      small
                      color="green"
                      dark
                      @click="getFile(attached.id)"
                      v-on="on"
                    >
                      <v-icon
                        dark
                        v-on="on"
                      >
                        fa-cloud-download-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                {{ getNameAttached(attached.id) }}
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      icon
                      small
                      color="red"
                      dark
                      @click="delFile(attached.id)"
                      v-on="on"
                    >
                      <v-icon
                        dark
                        v-on="on"
                      >
                        fa-trash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </template>
        <v-btn
          color="primary"
          class="mt-3"
          @click="e1 = 5"
        >
          Siguiente
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        step="5"
        editable
      >
        Productos Integrados al Sistema nacional de Investigación
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-select
          v-model="projectEdited.hours"
          autofocus
          :items="hours"
          :rules="rules.hours"
          label="* Intensidad Horaria"
        />
        <!--Resultados-->
        <Result
          :project-edited="projectEdited"
          :array-products="arrayProducts"
        />
        <v-btn
          class="mt-3"
          color="primary"
          @click="e1 = 1"
        >
          Siguiente
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>
<script>
  import Investigator from './Investigator';
  import Result from './Result';
  import { objectToFormData, createLinkFile, removeAccents } from '../../util/functions';
  import { URL_APP } from "../../util/constants";

  export default{
    name: 'FormProyect',
    components: {
      Investigator,
      Result,
    },    
    props: {
      projectEdited: {
        type: Object,
        default: null
      },
      editedIndex: {
        type: Number,
        default: -1
      }
    },
    data() {
      return {
        edited: false,
        e1: 1,
        valid: null,
        rules: {
          announcement_id: [v => (v || 0) > 0 || 'Campo requerido'],
          title: [
            v => (v || '').length > 0 || 'Campo requerido',
          ],
          attached: [
            v => (v || '').length > 0 || 'Campo requerido',
            v => !v || v.size < 100000000 || 'No puede Subir Archivos mayor a 10 GB!',
          ],
          hours: [ v => !!v || 'Campo Requerido',  ],
        },
        sectionals:[],
        steep: 1,
        convocatorias: [],
        arrayProducts:[],
        hours:[],
        productTypes:[],
        products:[],
        bandera1:true,
        //proyectoEdited: [],
      }
    },
    computed: {
      totalHoras(){
        var total = 0
        this.projectEdited.internal_investigators.forEach(function(investigator){
          total = total + parseInt(investigator.payroll_hours)
        })
        return total
      }
    },

    watch: {
      editedIndex(val){
        this.getConvocatorias(val);
      },
      'projectEdited.hours'(val,valant){
        if((valant != '' && valant != null) && (val != null && val != '')){
          if(this.projectEdited.results.length>0){
            if(this.bandera1){
              this.$root.$confirm('Cambiar Intensidad Horaria', 'Se eliminarán todos los Resultados, Realmente desea realizar esta acción?', { color: 'red' })
              .then((res) => {
                if(res){
                  this.projectEdited.results = []
                }else{
                  this.projectEdited.hours = valant
                  this.bandera1 = false
                }
              })
            }else{
              this.bandera1 = true
            }
          }
        }
      },
      /*'projectEdited.attacheds'(val,valant){

      }*/
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize (){
        //Convocatorias
        this.getConvocatorias(this.editedIndex)
        this.getSectionals()
        this.getHours()
      },

      existAttached(id){
        var bandera = false
        if(Array.isArray(this.projectEdited.attacheds))
        this.projectEdited.attacheds.forEach(function(attached){
          if(id==attached.attached_id){
            bandera = true
          }
        })
        return bandera
      },

      //Taer los Productos
      getHours () {
        let url = URL_APP+"/api/siiicca/selproducts"
        var _this2 = this
        this.hours = []
        this.$http.get(url)
        .then(res => {
          this.arrayProducts = res.data
          //Recorrer los productos para traer las horas
          this.arrayProducts.forEach(function(product){
            product.types.forEach(function(type){
              var bandera = false
              _this2.hours.forEach(function(hour){
                if(hour == type.pivot.hours)
                bandera = true
              })
              if(!bandera)
              _this2.hours.push(type.pivot.hours)
            })
          })
        });
      },

      getConvocatorias(value) {
        var url = ""
        if(value === -1)
          url = URL_APP+'/api/siiicca/selannouncements'
        else
          url = URL_APP+'/api/siiicca/announcements'
        this.$http.get(url)
          .then(response => {
            this.convocatorias = response.data;
          });
      },

      //Taer las Seccionales
      getSectionals () {
        let url = URL_APP+"/api/selsectionals"
        this.$http.get(url)
        .then(res => {
          this.sectionals = res.data;
        });
      },

      getConvocatoria() {
        var _this12 = this
        this.projectEdited.attacheds = []
        this.convocatorias.forEach(function(announcement){
          //Busca la convocatoria
          if (announcement.id==_this12.projectEdited.announcement_id){
            _this12.projectEdited.announcement = announcement
            if(Array.isArray(announcement.attacheds))
            announcement.attacheds.forEach(function(attached1){
              var attached = {attached_id:attached1.id, file:null}
              _this12.projectEdited.attacheds.push(attached)
            })
          }
        })
      },

      getFile(item) {
        var params = {'id':this.projectEdited.id, 'file':item}
        var name = removeAccents(item.id+"."+item.title,  80)+".pdf"
        this.$root.$loadApp(true)
        this.$http.get(URL_APP+"/api/siiicca/downloadfile", {responseType: 'blob', params})
        .then(response => {
          createLinkFile(response, name)
          this.$root.$alert({text:"Archivo Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Descargar el Archivo",color:"error",timeout:1000})
          this.$root.$loadApp(false)
        });
      },

      delFile(id) {
        var _this2 = this
        this.$root.$confirm('Eliminar Anexo', 'Realmente desea Eliminar este Anexo?', { color: 'red' })
        .then((res) => {
          if(res){
            //attached 1
            if(Array.isArray(this.projectEdited.attacheds))
            this.projectEdited.attacheds.forEach(function(attached, index){
              if(id==attached.attached_id){
                _this2.projectEdited.attacheds[index].id=null
                _this2.projectEdited.attacheds[index].name=null
              }
            })
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      getNameAttached(id){
        var name = ""
        if(Array.isArray(this.projectEdited.attacheds))
        this.projectEdited.attacheds.forEach(function(attached){
          if(id==attached.attached_id){
            name = attached.name
          }
        })
        return name
      },


      addInvestigator(investEdited, investIndex, type_third){
        investEdited.type_third = type_third;
        if (investIndex > -1) {
          type_third=="Interno" ? 
          Object.assign(this.projectEdited.internal_investigators[investIndex], investEdited) :
          Object.assign(this.projectEdited.external_investigators[investIndex], investEdited)
          this.$root.$alert({text:"Edición Correcta",color:"success",timeout:1000})
        }else{
          type_third=="Interno" ? 
          this.projectEdited.internal_investigators.push(investEdited) :
          this.projectEdited.external_investigators.push(investEdited) 
          this.$root.$alert({text:"Añadido Correctamente",color:"success",timeout:1000})
        }
        
      },

      async save() {
        const formData = objectToFormData(this.projectEdited)
        this.$root.$loadApp(true)
        await this.$http.post(URL_APP+'/api/siiicca/projects',formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(response => {
          if(response.data.error){
            this.$root.$alert({text:response.data.error,color:"error",timeout:2000})
          }else{
            this.$emit("close3")
            this.$root.$alert({text:"Borrador Guardado Correctamente",color:"success",timeout:1000})
          }
        })
        .catch(error => {
          if(error.response){
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          }else{
            this.$root.$alert({text:"No se pudo Guardar el Proyecto, por favor Revise",color:"error",timeout:2000})
          }
        });
        this.$root.$loadApp(false)
      },
    }
  }
</script>


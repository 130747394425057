import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import {initialize} from './helpers/general';
import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*'

Vue.config.productionTip = false
Vue.prototype.$http = axios;

initialize(store, router, Vue.prototype.$http);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

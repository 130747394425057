<template>
  <v-dialog
    v-model="drawer"
    max-width="500"
    scrollable
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <span>Cambio de Contraseña</span>
        <v-spacer />
        <v-btn
          text
          small
          @click="close"
        >
          X
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="changepass"
          v-model="valid"
          @submit.prevent="false"
        >
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-alert
                  class="py-2"
                  dense
                  outlined
                  type="error"
                >
                  Para cambiar la Contraseña del SAC debe ingresar al siguiente <strong><a
                    href="http://oas.usta.edu.co:8080/sgacampus/"
                    target="_blank"
                  >link </a></strong>
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.before"
                  prepend-icon="fa-asterisk"
                  name="before"
                  label="Contraseña Anterior"
                  type="password"
                  :rules="rules.before"
                  required
                  autofocus
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.password"
                  prepend-icon="fa-asterisk"
                  name="password"
                  label="Nueva Contraseña"
                  type="password"
                  :rules="rules.password"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.passVerifi"
                  prepend-icon="fa-asterisk"
                  name="passVerifi"
                  label="Repita Nueva Contraseña"
                  type="password"
                  :rules="rules.passVerifi"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="disableButton"
          outlined
          :loading="loading"
          @click="save"
        >
          Cambiar
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          :loading="loading"
          @click="close"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { URL_APP } from "../util/constants";
export default {
  name: 'ChangePass',
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return { 
      disabledSubmit: true,
      loading:false,
      form:{
        before: '',
        password: '',
        passVerifi: '',
      },
      rules: {
        before: [
          v => !!v || 'Contraseña Requerida',
        ],
        password: [
          v => !!v || 'Contraseña Requerida',
          v => v.length >= 8 || 'Mínimo 8 caracteres',
        ],
        passVerifi: [
          v => !!v || 'Contraseña Requerida',
          v => v==this.form.password || 'Contraseñas no coinciden',
        ],
      },
      valid: null,
    }
  },
  computed: {
    disableButton: function (){
      if(this.valid){
        return false
      }else{
        return true
      }
    }
  },
  methods: {
    async save(){
      this.loading = true;
      await this.$http.post(URL_APP+'/api/auth/changepasswd', this.form)
      .then((res)=>{
        //La Respuesta es Correcta
        this.$root.$alert({text:res.data.message,color:"success",timeout:2000})
        this.close()
      })
      .catch((error)=>{
        this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
      });
      this.loading = false
    },

    close () {
      this.form.before= ''
      this.form.password= ''
      this.form.passVerifi= ''
      this.$emit('changePasswd', false)
    },
  },
}
</script>

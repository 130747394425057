<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="products"
      class="elevation-1 mx-1 mt-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>Productos</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                x-small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="newItem()"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo Producto</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="900px"
            persistent
          >
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <span>{{ formTitle }}</span>
                <v-spacer />
                <v-btn
                  text
                  @click="close"
                >
                  X
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="itemEdited.id"
                          :rules="rules.product_id"
                          :items="arrayProducts"
                          item-text="name"
                          item-value="id"
                          label="* Producto de Investigación"
                          placeholder="Seleccionar..."
                          clearable
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="save"
                >
                  Aceptar
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="close"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  name: "Product",
  props: {
    products: {
      type: Array,
      default: null
    },
    arrayProducts: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      indexEditing: -1,
      headers: [
        { text: 'Producto', value: 'name' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      valid: null,
      dialog: false,
      itemEdited: {
        product: {id:0, name:''},
        product_type: {id:0, name:''},
      },
      defaultItem: {
        product: {id:0, name:''},
        product_type: {id:0, name:''},
      },
      rules: {
        product_id: [
          v => !!v || 'Campo Requerido',
        ],
      },
    }
  },

  computed: {
    formTitle () {
      return this.indexEditing === -1 ? 'Nuevo Producto ' : 'Editar Producto '
    },
    viewMode () {
      return this.indexEditing === -1 ? 'Guardar' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {

    initialize () {
    },

    close () {
      this.dialog = false
      this.itemEdited = Object.assign({}, this.defaultItem)
      this.itemEdited.product = Object.assign({}, this.defaultItem.product)
      this.indexEditing = -1
    },

    deleteItem (item) {
      const index = this.products.indexOf(item)
      this.$root.$confirm('Eliminar Item', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.products.splice(index, 1)
          this.$root.$alert({text:"¡ Resultado Eliminado !",color:"success",timeout:1000})
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    editItem (item) {
      this.indexEditing = this.products.indexOf(item)
      this.itemEdited = Object.assign({}, item)
      this.dialog = true
    },

    newItem () {
        this.dialog = true
    },

    save() {
      this.$refs.form.validate()
      if(!this.valid){
        this.$root.$alert({text:"No se puede Guardar, por favor revise los errores",color:"error",timeout:2000})
      }else{
        //Añadimos El Producto a al Estrategia
        if (this.indexEditing > -1) {
          Object.assign(this.products[this.indexEditing], this.itemEdited)
          this.$root.$alert({text:"Edición Correcta",color:"success",timeout:1000})
          this.close()
        }else{
          //Nuevo, revisar que no exista
          var exist = this.products.find(element => element.id == this.itemEdited.id);
          if(!exist){
            exist = this.arrayProducts.find(element => element.id == this.itemEdited.id);
            this.products.push(exist)
            this.$root.$alert({text:"Añadido Correctamente",color:"success",timeout:1000})
            this.close()
            this.countNew ++
          }else{
            this.$root.$alert({text:"Producto ya Existe",color:"error",timeout:1000})
          }
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="title"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>Convocatorias</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
          />
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 my-2"
                small
                fab
                dark
                color="primary"
                v-on="on"
                @click.stop="dialog = true"
              >
                <v-icon dark>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            max-width="1000px"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombre"
                          :rules="rules.name"
                          autofocus
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.type" 
                          :rules="rules.type"
                          :items="types"
                          label="* Tipo de Convocatoria"
                          placeholder="Seleccione el Tipo de Convocatoria"
                          required
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.description"
                          name="description"
                          label="Descripción"
                          :rules="rules.description"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-menu
                          v-model="menFechIni"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.fecha_inicio"
                              label="Fecha de Inicio"
                              :rules="rules.fecha_inicio"
                              prepend-icon="fa-calendar-alt"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="editedItem.fecha_inicio"
                            :min="fechaHoy()"
                            @input="menFechIni = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-menu
                          v-model="menFechFin"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.fecha_fin"
                              label="Fecha Final"
                              :rules="rules.fecha_fin"
                              prepend-icon="fa-calendar-alt"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="editedItem.fecha_fin"
                            :min="editedItem.fecha_inicio"
                            @input="menFechFin = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-switch
                          v-model="editedItem.state"
                          label="Estado"
                          color="primary"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="secondary darken-1"
                  outlined
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  outlined
                  @click="save"
                >
                  {{ viewMode }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.state="props">
        {{ stateItem(props.item) }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="info"
              @click="editItem(item)"
              v-on="on"
            >
              fa-pen
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-1"
              color="error"
              @click="deleteItem(item)"
              v-on="on"
            >
              fa-trash
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              color="green"
              dark
              @click="initialize()"
              v-on="on"
            >
              <v-icon
                dark
                v-on="on"
              >
                fa-sync-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import { fechaHoy } from '../../util/functions';
  import { URL_APP } from "../../util/constants";
  export default{
    name:'Announcement',
    data() {
      return {
        dialog: false,
        menFechIni: false,
        menFechFin: false,
        search: '',
        rules: {
            name: [
              v => !!v || 'Campo requerido',
              v => v.length >= 5 || 'Mínimo 5 caracteres',
              v => v.length < 255 || 'Máximo 255 letras',
            ],
            description: [
              v => !!v || 'Campo Requerido',
              v => v.length < 255 || 'Máximo 255 letras',
            ],
            fecha_inicio: [
              v => !!v || 'Campo Requerido',
              //v => v < this.fechaHoy() || 'La fecha debe ser mayor a hoy',
            ],
            fecha_fin: [
              v => !!v || 'Campo Requerido',
              v => v >= this.editedItem.fecha_inicio || 'La fecha debe ser mayor a la inicial',
            ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Tipo', value: 'type' },
            { text: 'Fecha Inicio', value: 'fecha_inicio' },
            { text: 'Fecha Final', value: 'fecha_fin' },
            { text: 'Estado', value: 'state' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        types: [
          'Multicampus',
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {id: 0, name: '', type:'', description: '', fecha_inicio: '', fecha_fin: '', state: true},
        defaultItem: {id: 0, name: '', type:'', description: '', fecha_inicio: '', fecha_fin: '', state: true},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/siiicca/announcements')
        .then(response => {
          this.desserts = response.data;
          this.$root.$loadApp(false)
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener las Convocatorias",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      fechaHoy(){
        fechaHoy()
      },

      deleteItem (item) {
        var url = URL_APP+"/api/siiicca/announcements/" + item.id;
        this.$root.$confirm('Eliminar Convocatoria', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            })
            .catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        this.$refs.form.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "PUT"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/siiicca/announcements',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>

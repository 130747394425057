<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
      persistent
    >
      <v-card>
        <v-toolbar dense color="primary" dark>
          Relaciones de la Seccional {{ editedItem.name }}
        </v-toolbar>
        <v-card-text>
          <v-card class="mt-2">
            <v-card-title>
              Divisiones
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-text-field
                v-model="searchDivision"
                append-icon="fa-search"
                label="Buscar"
                single-line
                hide-details
              />
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2 my-2"
                    small
                    fab
                    dark
                    color="primary"
                    v-on="on"
                    @click.stop="newDivision()"
                  >
                    <v-icon dark>
                      fa-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-data-table dense :items-per-page="5"
                v-model="selectDivision" :search="searchDivision"
                :headers="headerDivisions"
                :items="itemsSave"
                item-key="id"
                show-select single-select
                class="elevation-1"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'fa-angle-double-left',
                  lastIcon: 'fa-angle-double-right',
                  prevIcon: 'fa-angle-left',
                  nextIcon: 'fa-angle-right'
                }"
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-1"
                        color="error"
                        @click="deleteDivision(item)"
                        v-on="on"
                      >
                        fa-trash
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card class="mt-2">
            <v-card-title>
              Facultades
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-text-field
                v-model="searchFaculty"
                append-icon="fa-search"
                label="Buscar"
                single-line
                hide-details
              />
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2 my-2"
                    small
                    fab
                    dark
                    color="primary"
                    v-on="on"
                    @click.stop="newFaculty()"
                  >
                    <v-icon dark>
                      fa-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-data-table dense :items-per-page="5"
                v-model="selectFaculty" :search="searchFaculty"
                :headers="headerFaculties"
                :items="facultySelect"
                item-key="id"
                show-select single-select
                class="elevation-1"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'fa-angle-double-left',
                  lastIcon: 'fa-angle-double-right',
                  prevIcon: 'fa-angle-left',
                  nextIcon: 'fa-angle-right'
                }"
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-1"
                        color="error"
                        @click="deleteFaculty(item)"
                        v-on="on"
                      >
                        fa-trash
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card class="mt-2">
            <v-card-title>
              Programas
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-text-field
                v-model="searchFaculty"
                append-icon="fa-search"
                label="Buscar"
                single-line
                hide-details
              />
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2 my-2"
                    small
                    fab
                    dark
                    color="primary"
                    v-on="on"
                    @click.stop="newProgram()"
                  >
                    <v-icon dark>
                      fa-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-data-table dense :items-per-page="5"
                :headers="headerPrograms" :search="searchProgram"
                :items="programSelect"
                item-key="id"
                class="elevation-1"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'fa-angle-double-left',
                  lastIcon: 'fa-angle-double-right',
                  prevIcon: 'fa-angle-left',
                  nextIcon: 'fa-angle-right'
                }"
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-1"
                        color="error"
                        @click="deleteProgram(item)"
                        v-on="on"
                      >
                        fa-trash
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <!--Dialogo Escoger Divisiones-->
          <v-dialog
            v-model="dialogDivision"
            scrollable
            persistent
            max-width="500px"
            transition="dialog-transition"
          >
          <v-card>
            <v-toolbar dense color="primary" dark>
              <v-toolbar-title>Divisiones</v-toolbar-title>
              <v-spacer/>
              <v-btn
                text
                small
                @click="dialogDivision=false"
              >
                X
              </v-btn>
            </v-toolbar>
              <v-card-text class="mt-2">
                <v-autocomplete
                  v-model="itemsDivision"
                  :items="divisions"
                  item-value="id"
                  item-text="name"
                  chips
                  small-chips
                  label="Por favor Selecione las Divisiones"
                  multiple
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="secondary darken-1"
                  outlined
                  @click="dialogDivision=false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  outlined
                  @click="saveDivision"
                >
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--Dialogo Escoger Facultades-->
          <v-dialog
            v-model="dialogFaculty"
            scrollable
            persistent
            max-width="500px"
            transition="dialog-transition"
          >
          <v-card>
            <v-toolbar dense color="primary" dark>
              <v-toolbar-title>Facultades</v-toolbar-title>
              <v-spacer/>
              <v-btn
                text
                small
                @click="dialogFaculty=false"
              >
                X
              </v-btn>
            </v-toolbar>
              <v-card-text class="mt-2">
                <v-autocomplete
                  v-model="itemsFaculty"
                  :items="faculties"
                  item-value="id"
                  item-text="name"
                  chips
                  small-chips
                  label="Por favor Selecione las Facultades"
                  multiple
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="secondary darken-1"
                  outlined
                  @click="dialogFaculty=false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  outlined
                  @click="saveFaculty"
                >
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--Dialogo Escoger Programas-->
          <v-dialog
            v-model="dialogProgram"
            scrollable
            persistent
            max-width="500px"
            transition="dialog-transition"
          >
          <v-card>
            <v-toolbar dense color="primary" dark>
              <v-toolbar-title>Programas</v-toolbar-title>
              <v-spacer/>
              <v-btn
                text
                small
                @click="dialogProgam=false"
              >
                X
              </v-btn>
            </v-toolbar>
              <v-card-text class="mt-2">
                <v-autocomplete
                  v-model="itemsProgram"
                  :items="programs"
                  item-value="id"
                  item-text="name"
                  chips
                  small-chips
                  label="Por favor Selecione los Programas"
                  multiple
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="secondary darken-1"
                  outlined
                  @click="dialogProgram=false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  outlined
                  @click="saveProgram"
                >
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            outlined
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>  
</template>

<script>
import { URL_APP } from '../../util/constants'
export default {
  name: 'RelationshipSectional',
  props: {
    editedItem: null,
    divisions: null,
    faculties: null,
    programs: null
  },
  data () {
    return {
      dialog: false,
      dialogDivision: false,
      dialogFaculty: false,
      dialogProgram: false,
      searchDivision: '',
      searchFaculty: '',
      searchProgram: '',
      selectDivision : [],
      selectFaculty: [],
      headerDivisions: [
        { text: 'Nombre', value: 'name' },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
      headerFaculties: [
        { text: 'Nombre', value: 'name' },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
      headerPrograms: [
        { text: 'Nombre', value: 'name' },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
      indexDivision: -1,
      indexFaculty: -1,
      indexProgram: -1,
      facultySelect: [],
      programSelect: [],
      itemsDivision: [],
      itemsFaculty: [],
      itemsProgram: [],
      itemsSave: []
    }
  },
  watch: {
    selectDivision (val) {
      this.facultySelect = val[0] ? val[0].faculties : []
      this.indexDivision = val[0] ? this.itemsSave.indexOf(val[0]) : -1
      this.selectFaculty = []
    },
    selectFaculty (val) {
      this.programSelect = val[0] ? val[0].programs : []
      this.indexFaculty = val[0] ? this.itemsSave[this.indexDivision].faculties.indexOf(val[0]) : -1
    },
  },
  methods: {
    initialize (){
    },

    async save () {
      this.$root.$loadApp(true)
      try{
        var resp = await this.$http.post(URL_APP+'/api/sectional_relationship', { id: this.editedItem.id, relationship: this.itemsSave})
        this.$root.$alert({text:resp.data.message,color:'success',timeout:1000})
        this.dialog = false
        this.$emit('initParent')
      }catch(err){ console.error(err)
        this.$root.$alert({text:'Ha Ocurrido Un error, por favor comuniarse con el Administrador',color:'error',timeout:2000})
      }
      this.$root.$loadApp(false)
    },
    close () {
      this.$root.$confirm('No guardar Cambios', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.dialog = false
          this.$root.$alert({text:"OK",color:"success", timeout:1000})
        }else{
          this.$root.$alert({text:"Seguir Editando...",color:"warning",timeout:1000})
        }
      })
    },
    async showDialog () {
      this.selectDivision = []
      this.selectFaculty = []
      await this.fillPivot()
      this.dialog = true
    },

    fillPivot () {
      var _this2 = this
      this.itemsSave = []
      var i = 0 , j = 0 , k = 0
      this.editedItem.divisions.forEach(e => {
        _this2.itemsSave[i] = e
        _this2.itemsSave[i].faculties = Array()
        j = 0
        _this2.editedItem.faculties.forEach(e2 => {
          if(e2.division_id == e.id){
            _this2.itemsSave[i].faculties[j] = e2
            _this2.itemsSave[i].faculties[j].programs = Array()
            k = 0
            _this2.editedItem.programs.forEach(e3 => {
              if(e3.division_id == e.id && e3.faculty_id == e2.id){
                _this2.itemsSave[i].faculties[j].programs[k] = e3
                k++
              }
            })
            j++
          }
        })
        i++
      });
    },

    deleteDivision (item) {
      this.$root.$confirm('Eliminar Division', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.indexDivision = this.itemsSave.indexOf(item)
          this.itemsSave.splice(this.indexDivision, 1)
          this.selectDivision = []
          this.selectFaculty = []
          this.$root.$alert({text:"Eliminado con Éxito",color:"success", timeout:1000})
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    deleteFaculty (item) {
      this.$root.$confirm('Eliminar Facultad', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.indexFaculty = this.itemsSave[this.indexDivision].faculties.indexOf(item)
          this.itemsSave[this.indexDivision].faculties.splice(this.indexFaculty, 1)
          this.facultySelect = this.itemsSave[this.indexDivision].faculties
          this.selectFaculty = []
          this.$root.$alert({text:"Eliminado con Éxito",color:"success", timeout:1000})
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    deleteProgram (item) {
      this.$root.$confirm('Eliminar Programa', 'Esta Seguro?', { color: 'red' })
      .then((res) => {
        if(res){
          this.indexProgram = this.itemsSave[this.indexDivision].faculties[this.indexFaculty].programs.indexOf(item)
          this.itemsSave[this.indexDivision].faculties[this.indexFaculty].programs.splice(this.indexProgram, 1)
          this.programSelect = this.itemsSave[this.indexDivision].faculties[this.indexFaculty].programs
          this.$root.$alert({text:"Eliminado con Éxito",color:"success", timeout:1000})
        }else{
          this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
        }
      })
    },

    newDivision () {
      this.itemsDivision = this.itemsSave
      this.dialogDivision = true
    },

    newFaculty () {
      if(this.indexDivision > -1){
        this.itemsFaculty = this.facultySelect
        this.dialogFaculty = true
      } else
        this.$root.$alert({text:"Debe Seleccionar la División",color:"error",timeout:1000})
    },

    newProgram () {
      if(this.indexDivision > -1 && this.indexFaculty > -1){
        this.itemsProgram = this.programSelect
        this.dialogProgram = true
      } else
        this.$root.$alert({text:"Debe Seleccionar la División y Facultad",color:"error",timeout:1000})
    },

    async saveDivision () {
      var _this2 = this
      var newItems = Array()
      await this.itemsDivision.reduce(async (promise, e) => {
        await promise;
        var itemDiv = _this2.itemsSave.find(e2 => e2.id == e)
        itemDiv = itemDiv? itemDiv : _this2.divisions.find(e3 => e3.id == e)
        if (!itemDiv.faculties) itemDiv.faculties = Array()
        newItems.push(itemDiv)
      }, Promise.resolve());
      this.itemsSave = newItems
      this.dialogDivision = false
    },

    async saveFaculty () {
      var _this2 = this
      var newItems = Array()
      await this.itemsFaculty.reduce(async (promise, e) => {
        await promise;
        var itemDiv = _this2.itemsSave[_this2.indexDivision].faculties.find(e2 => e2.id == e)
        itemDiv = itemDiv? itemDiv : _this2.faculties.find(e3 => e3.id == e)
        if (!itemDiv.programs){
          itemDiv.programs = Array()
          itemDiv.division_id = _this2.itemsSave[_this2.indexDivision].id
        }
        newItems.push(itemDiv)
      }, Promise.resolve());
      this.itemsSave[this.indexDivision].faculties = newItems
      this.facultySelect = newItems
      this.dialogFaculty = false
    },

    async saveProgram () {
      var _this2 = this
      var newItems = Array()
      await this.itemsProgram.reduce(async (promise, e) => {
        await promise;
        var itemDiv = _this2.itemsSave[_this2.indexDivision].faculties[_this2.indexFaculty].programs.find(e2 => e2.id == e)
        itemDiv = itemDiv? itemDiv : _this2.programs.find(e3 => e3.id == e)
        if (!itemDiv.programs){
          itemDiv.division_id = _this2.itemsSave[_this2.indexDivision].id
          itemDiv.faculty_id = _this2.itemsSave[_this2.indexDivision].faculties[_this2.indexFaculty].id
        }
        newItems.push(itemDiv)
      }, Promise.resolve());
      this.itemsSave[this.indexDivision].faculties[_this2.indexFaculty].programs = newItems
      this.programSelect = newItems
      this.dialogProgram = false
    },
  }
}
</script>

<style>

</style>
<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Estrategias de Formación</span>
      </v-card-title>
      <v-card-text>
        <!-- <template v-if="path">
          <FilterQuery
            id="filterQuery"
            :filters="filters"
            :field-filters="fieldFilters"
            @freshFilters="initialize"
          />
        </template> -->

        <v-data-table v-model="selected" :headers="headers" :items="items" :search="search" :loading="loading"
          sort-by="name" item-key="id" :items-per-page.sync="itemsPerPage" :page="page" :show-select="path"
          hide-default-footer class="elevation-1" >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field v-model="search" append-icon="fa-search" label="Buscar" single-line hide-details />
              <v-spacer />
              <!-- Filtros  -->
              <template>
                <v-icon class="mx-2">fa-filter</v-icon>
                <v-select class="mt-5" :items="filterPeriods" item-text="description" item-value="id"
                  label="Filtrar por periodo" @change="filterPeriod" v-model="idFilterPeriod"></v-select>
                <span>
                  <v-icon small v-if="idFilterPeriod > 0" @click="resetFilter">fa-times-circle</v-icon>
                </span>
              </template>
              <!-- Fin de filtros -->
              <template v-if="path">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2 my-2" small text color="green" v-on="on" @click.stop="getExcel">
                      <v-icon dark> fa-file-excel </v-icon>
                    </v-btn>
                  </template>
                  <span>Excel</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2 my-2" small text color="primary" v-on="on" @click.stop="getAnexos">
                      <v-icon dark> fa-file-download </v-icon>
                    </v-btn>
                  </template>
                  <span>Anexos</span>
                </v-tooltip>
              </template>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="newStrategy">
                    <v-icon dark> fa-plus </v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:item.roles="{ item }">
            <v-chip v-for="(rol, indexRol) in item.roles" :key="indexRol" color="secondary" dark>
              {{ rol }}
            </v-chip>
          </template>

          <template v-slot:item.action="{ item }">
            <v-tooltip bottom v-if="item.period_id === active_period">
              <!-- <v-tooltip bottom> -->
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-1" color="info" @click="editItem(item)" v-on="on">
                  fa-pen
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.period_id === active_period">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-1" color="error" @click="deleteItem(item)" v-on="on">
                  fa-trash
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-1" color="orange lighten-1" @click="pdfItem(item)" v-on="on">
                  fa-file-pdf
                </v-icon>
              </template>
              <span>Generar PDF</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.state == 'Activo' &&
              item.period_id != active_period &&
              !item.expiration
              ">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-1" color="primary" @click="upgradePeriod(item)" v-on="on">
                  fa-history
                </v-icon>
              </template>
              <span>Actualizar periodo</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.expiration &&
              !(item.state == 'Vencido' || item.state == 'Finalizado')
              ">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-1" color="red lighten-1" v-on="on" @click="expirationItem(item)">
                  fa-ban
                </v-icon>
              </template>
              <span>Vencer </span>
            </v-tooltip>

            <v-tooltip v-if="item.state == 'Activo' && item.period_id === active_period" bottom>
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-1" color="success" @click="finishItem(item)" v-on="on">
                  fa-clipboard-list
                </v-icon>
              </template>
              <span>Informe final</span>
            </v-tooltip>
          </template>

          <template v-slot:item.faculties="{ item }">


            <span v-for="(faculty, index) in item.faculties" :key="index">

              {{ faculty.name }}

              <!-- Si no es el último elemento, agregar una coma y espacio -->
              <span v-if="index < item.faculties.length - 1"> - </span>
            </span>

          </template>

          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on">
                  <v-icon dark v-on="on"> fa-sync-alt </v-icon>
                </v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
            <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
          </template>
          <template v-slot:footer>
            <v-row class="mt-2" align="center" justify="center">
              <v-spacer />
              <span class="grey--text">Items por página</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                    {{ itemsPerPage }}
                    <v-icon>fa-angle-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                    @click="updateItemsPerPage(number)">
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <span class="mr-4 grey--text">
                Página {{ page }} de {{ numberOfPages }}
              </span>
              <v-btn fab x-small dark color="blue darken-3" class="mr-1" @click="formerPage">
                <v-icon>fa-angle-left</v-icon>
              </v-btn>
              <v-btn fab x-small dark color="blue darken-3" class="ml-1 mr-4" @click="nextPage">
                <v-icon>fa-angle-right</v-icon>
              </v-btn>
            </v-row>
          </template>


        </v-data-table>

        <!--Formulario de la Estrategia-->
        <FormStrategy ref="strategy" :strategy-edited="strategyEdited" :editing="editing" @initialize="initialize" />

        <!--Formulario de Finalización-->
        <FinishStrategy :dialog="dialogFinish" :strategy-edited="strategyEdited" @close="close"
          @initialize="initialize" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { URL_APP } from "../../util/constants";
import FormStrategy from "./FormStrategy";
import FinishStrategy from "./FinishStrategy";
import FilterQuery from "../general/FilterQuery";
import { createLinkFile, removeAccents } from "../../util/functions";
export default {
  name: "MyStrategies",
  components: {
    FormStrategy,
    FinishStrategy,
    FilterQuery,
  },
  data() {
    return {
      filterPeriods: [],
      // loading: false,
      dialog: false,
      dialogFinish: false,
      final_report_file: null,
      loading: false,
      errors: [],
      itemsPerPageArray: [5, 10, 15, 20, 30, 50, 100],
      page: 1,
      itemsPerPage: 5,
      search: "",
      selected: [],
      active_period: 0,
      idFilterPeriod: 0,
      periods_filter: [], //Cuando selecciona el periodo se almacenan aquí
      rules: {
        name: [
          (v) => !!v || "Campo requerido",
          (v) => v.length >= 5 || "Mínimo 5 caracteres",
        ],
        email: [(v) => !!v || "Campo Requerido"],
        password: [(v) => !!v || "Campo Requerido"],
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Fecha", value: "created_at" },
        // { text: "Seccional", value: "sectional.name" },
        { text: "Facultad / Programa", value: "faculties", sortable: false, width: '30%' },
        { text: "Usuario", value: "user.name" },
        { text: "Estrategia", value: "strategy" },
        { text: "Estado", value: "state" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      editing: false,

      strategyEdited: {
        id: null,
        period_id: null,
        sectional_id: null,
        strategy: "",
        name: null,
        overall_objective: null,
        specific_goal: null,
        duration: null,
        research_hotbed_id: null,
        problem_nucleus: null,
        conformation_date: null,
        reward: null,
        entity_id: null,
        period: {},
        sectional: {},
        approvement_letter_file: null,
        leader: {
          third: { identification: "", name: "", email: "" },
          sectional_id: "",
        },
        divisions: [],
        faculties: [],
        programs: [],
        actors: [],
        activities: [],
        products: [],
        investigation_lines: [],
        action_fields: [],
        approvement_letter: null,
      },
      defaultItem: {
        id: null,
        period_id: null,
        sectional_id: null,
        strategy: "",
        name: null,
        overall_objective: null,
        specific_goal: null,
        duration: null,
        research_hotbed_id: null,
        problem_nucleus: null,
        conformation_date: null,
        reward: null,
        entity_id: null,
        period: {},
        sectional: {},

        approvement_letter_file: null,
        leader: {
          third: { identification: "", name: "", email: "" },
          sectional_id: "",
        },
        divisions: [],

        faculties: [],
        //Agregado para periodos
        periodsName: [],
        filterPeriods: [],

        actors: [],
        activities: [],
        products: [],
        investigation_lines: [],
        action_fields: [],
        approvement_letter: null,
      },
      valid: true,
      filters: [
        {
          field: {
            field: "state",
            name: "Estado",
            type: "select",
            options: ["Borrador", "Enviado"],
          },
          type: "Igual",
          value_start: "Enviado",
          value_end: "",
        },
      ],
      fieldFilters: [
        {
          field: "state",
          name: "Estado",
          type: "select",
          options: ["Borrador", "Enviado"],
        },
        { field: "announcement.name", name: "Convocatoria", type: "text" },
        { field: "sectional.name", name: "Seccional", type: "text" },
      ],
    };
  },

  computed: {
    ...mapGetters(["loading"]),
    currentUser() {
      return this.$store.getters.currentUser;
    },
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    path() {
      return this.$route.name == "strategies" ? true : false;
    },
  },

  watch: {
    path() {
      this.initialize();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions(["getPrograms", "chgLoad"]),
    async initialize() {
      this.getPrograms();
      this.getPeriods(); //agregado para periodos
      this.getFilterPeriods(); //agregado para obtener todos periodos
      this.resetFilter();
      this.editing = false;
      this.strategyEdited = Object.assign({}, this.defaultItem);
      this.items = [];
      this.loading = true;
      var url = URL_APP;
      if (this.path) url = URL_APP + "/api/siiicca/strategies";
      else url = URL_APP + "/api/siiicca/my_strategies";
      await this.$http.get(url).then((res) => {
        this.items = res.data;
      });
      this.loading = false;
    },

    async getPeriods() {
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/selstrategy_periods"
        );

        this.periodsName = response.data.description;
        this.active_period = response.data.id;
        console.log(this.active_period);
      } catch (err) {
        console.error(err);
      }
    },

    async getFilterPeriods() {
      try {
        var response = await this.$http.get(
          URL_APP + "/api/siiicca/strategy_periods"
        );
        this.filterPeriods = response.data;
      } catch (err) {
        console.error(err);
      }
    },

    filterPeriod() {
      // console.log(this.idFilterPeriod)
      if (this.periods_filter.length > 0) {
        console.log("No Es vacio");
        this.items = this.periods_filter;
      }

      this.periods_filter = this.items;

      let filtered_periods = this.items.filter(
        (e) => e.period_id === this.idFilterPeriod
      );

      this.items = filtered_periods;
      // console.log(this.items);
      // console.log(this.periods_filter);
    },

    resetFilter() {
      this.idFilterPeriod = 0;
      this.items = this.periods_filter;
      this.periods_filter = [];
    },

    async newStrategy() {
      var period = null;
      await this.$http
        .get(URL_APP + "/api/siiicca/selstrategy_periods")
        .then((res) => {
          period = res.data;
        });
      if (period) {
        // this.strategyEdited.periods = period;
        this.strategyEdited = Object.assign({}, this.defaultItem);
        this.strategyEdited.leader = Object.assign({}, this.defaultItem.leader);
        this.strategyEdited.leader.third = Object.assign(
          {},
          this.defaultItem.leader.third
        );
        this.strategyEdited.actors = [];
        this.strategyEdited.divisions = [];
        this.strategyEdited.faculties = [];
        this.strategyEdited.programs = [];
        this.strategyEdited.activities = [];
        this.strategyEdited.products = [];
        this.strategyEdited.investigation_lines = [];
        this.strategyEdited.action_fields = [];
        setTimeout(() => {
          this.$refs.strategy.change();
        }, 300);
      } else {
        this.$root.$alert({
          text: "No se encuentra un Periodo Activo, por favor contactese con la persona encargada",
          color: "error",
          timeout: 4000,
        });
      }
    },

    editItem(item) {
      this.strategyEdited = Object.assign({}, item);
      if (this.strategyEdited.state != "Activo") {
        this.$root.$alert({
          text: "La Estrategia no tiene el Estado Necesario para Editar",
          color: "error",
          timeout: 4000,
        });
      }
      var leader = this.strategyEdited.actors.find(
        (sect) => sect.type == "Líder"
      );
      this.strategyEdited.leader = leader ? leader : this.defaultItem.leader;

      this.editing = true;
      setTimeout(() => {
        this.$refs.strategy.change();
      }, 300);
    },

    async deleteItem(item) {
      var url = URL_APP + "/api/siiicca/strategies/" + item.id;
      await this.$root
        .$confirm(
          "Eliminar Estrategia",
          "Esta Seguro de Eliminar esta Estrategia?",
          { color: "red" }
        )
        .then(async (res) => {
          if (res) {
            await this.$http
              .delete(url)
              .then((response) => {
                this.initialize();
                this.$root.$alert({
                  text: "¡ Estrategia Eliminada !",
                  color: "success",
                  timeout: 1000,
                });
              })
              .catch((error) => {
                console.error(error);
                this.$root.$alert({
                  text: "Imposible Eliminar Registro",
                  color: "error",
                  timeout: 1000,
                });
              });
          } else {
            this.$root.$alert({
              text: "Acción Cancelada",
              color: "warning",
              timeout: 1000,
            });
          }
        });
    },

    close() {
      this.strategyEdited = Object.assign({}, this.defaultItem);
      this.dialogFinish = false;
    },

    finishItem(item) {
      this.strategyEdited = Object.assign({}, item);
      this.dialogFinish = true;
    },

    async pdfItem(item) {
      this.$root.$loadApp(true);
      var name = item.name ? item.name : item.strategy;
      name = removeAccents(item.id + "." + name) + ".pdf";
      await this.$http
        .get(URL_APP + "/api/siiicca/strategypdf/" + item.id, {
          responseType: "blob",
        })
        .then((response) => {
          createLinkFile(response, name);
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
        })
        .catch((error) => {
          console.log(error);
          this.$root.$alert({
            text: "Error al Obtener el PDF",
            color: "error",
            timeout: 1000,
          });
        });
      this.$root.$loadApp(false);
    },

    async upgradePeriod(item) {
      //  Esto ya funciona
      await this.$root
        .$confirm(
          "Actualizar periodo de la estrategia",
          "Esta Seguro de actualizar el periodo de esta estrategia?",
          { color: "green" }
        )
        .then(async (res) => {
          if (res) {
            // console.log("Oka");
            await this.$http
              .post(URL_APP + "/api/siiicca/strategies", item, {
                params: {
                  action: "Migrate",
                },
              })
              .then((response) => {
                this.initialize();
                this.$root.$alert({
                  text: "¡ Estrategia Migrada !",
                  color: "success",
                  timeout: 1000,
                });
              })
              .catch((error) => {
                console.error(error);
                this.$root.$alert({
                  text: "Imposible Migrar Estrategia",
                  color: "error",
                  timeout: 1000,
                });
              });
          } else {
            this.$root.$alert({
              text: "Acción Cancelada",
              color: "warning",
              timeout: 1000,
            });
          }
        });
    },

    async expirationItem(item) {
      //  Esto ya funciona
      await this.$root
        .$confirm(
          "Vencimiento de la estrategia",
          "Esta Seguro de confirmar el vencimiento de esta estrategia?",
          { color: "orange" }
        )
        .then(async (res) => {
          if (res) {
            await this.$http
              .post(URL_APP + "/api/siiicca/expirationStrategy", item)
              .then((response) => {
                this.initialize();
                this.$root.$alert({
                  text: "¡ Estrategia Vencida !",
                  color: "success",
                  timeout: 1000,
                });
              })
              .catch((error) => {
                console.error(error);
                this.$root.$alert({
                  text: "Imposible Vencer Estrategia",
                  color: "error",
                  timeout: 1000,
                });
              });
          } else {
            this.$root.$alert({
              text: "Acción Cancelada",
              color: "warning",
              timeout: 1000,
            });
          }
        });
    },

    async getExcel() {
      var strategies = [];
      this.selected.forEach(function (strategy) {
        strategies.push(strategy.id);
      });
      var url =
        URL_APP + "/api/siiicca/strategyexcel/" + JSON.stringify(strategies);
      await this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          createLinkFile(response);
          this.$root.$alert({
            text: "Listado Generado con Éxito",
            color: "success",
            timeout: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$root.$alert({
            text: "Error al Obtener los Anexos",
            color: "error",
            timeout: 1000,
          });
        });
      this.$root.$loadApp(false);
    },

    async getAnexos() {
      var strategies = [];
      this.selected.forEach(function (strategy) {
        strategies.push(strategy.id);
      });
      var url =
        URL_APP + "/api/siiicca/getsanexos/" + JSON.stringify(strategies);
      this.$root.$loadApp(true);
      await this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          createLinkFile(response);
          this.$root.$alert({
            text: "Archivo Generado con Éxito",
            color: "success",
            timeout: 2000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$root.$alert({
            text: "Error al Obtener los Anexos",
            color: "error",
            timeout: 2000,
          });
        });
      this.$root.$loadApp(false);
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>

import Welcome from '../views/Welcome';
import Login from '../views/auth/Login';
import Recover from '../views/auth/Recover';
import FindRecover from '../views/auth/FindRecover';
//Configuración
import Profile from '../views/config/Profiles';
import User from '../views/config/Users';
//Tablas Básicas
import Subject from '../views/general/Subject';
import InvestigationLine from '../views/general/InvestigationLine';
import ActionField from '../views/general/ActionField';
import StrategyPeriod from '../views/general/StrategyPeriod';
import Sectional from '../views/general/Sectional';
import Division from '../views/general/Division';
import Faculty from '../views/general/Faculty';
import Program from '../views/general/Program/Program';
import InvestigationGroup from '../views/general/InvestigationGroup';
import ResearchHotbed from '../views/general/ResearchHotbed';
import Product from '../views/general/Product';
import ProductType from '../views/general/ProductType';
import Country from '../views/general/Country';
import City from '../views/general/City';
import Department from '../views/general/Department';
//Estrategias de Formación
import MyStrategies from '../views/strategy/Strategies';
import Strategies from '../views/strategy/Strategies';
//Proyectos de Investigación
import Announcement from '../views/project/Announcement';
import Project from '../views/project/Project';
import MyProjects from '../views/project/Project';

const TITLE = process.env.VUE_APP_TITLE+":."

export const routes = [
		{	path: '/welcome', name: 'welcome', component: Welcome, meta: {	title: ".:"+'Bienvenido - '+TITLE }},
		{ path: '/login', name: 'login', component: Login, meta: {	title: ".:"+'Iniciar Sesión - '+TITLE }},
		{	path: '/recover',	name: 'recover', component: Recover, meta: {	title: ".:"+'Recuperar Contraseña - '+TITLE }},
		{ path: '/findrecover/:t', name: 'findrecover',	component: FindRecover, meta: {	title: ".:"+'Recuperar Contraseña - '+TITLE }},
		{	path: '/profiles', name: 'profiles', component: Profile, meta: { requiresAuth: true, title: ".:"+'Perfiles - '+TITLE	}	},
		{	path: '/users',	name: 'users', component: User, meta: {	requiresAuth: true, title: ".:"+'Usuarios - '+TITLE } },
    //Estratégias de formación
		{ path: '/my_strategies', name: 'my_strategies', component: MyStrategies, meta: {	requiresAuth: true, title: ".:"+'Mis Estrategias - '+TITLE } },
		{ path: '/strategies', name: 'strategies', component: Strategies, meta: {	requiresAuth: true, title: ".:"+'Estrategias - '+TITLE } },
		{ path: '/subjects', name: 'subjects', component: Subject, meta: {	requiresAuth: true, title: ".:"+'Materias - '+TITLE } },
		{ path: '/investigation_lines', name: 'investigation_lines', component: InvestigationLine, meta: {	requiresAuth: true, title: ".:"+'Lineas de Investigación - '+TITLE } },
		{ path: '/research_hotbeds', name: 'research_hotbeds', component: ResearchHotbed, meta: { requiresAuth: true, title: ".:"+'Semilleros de Investigación - '+TITLE } },
		{ path: '/action_fields', name: 'action_fields', component: ActionField, meta: {	requiresAuth: true, title: ".:"+'Campos de Acción - '+TITLE } },
		{ path: '/strategy_periods', name: 'strategy_periods', component: StrategyPeriod, meta: {	requiresAuth: true, title: ".:"+'Periodos de Estrategias - '+TITLE } },
		{ path: '/investigation_groups', name: 'investigation_groups', component: InvestigationGroup, meta: {	requiresAuth: true, title: ".:"+'Grupos de Investigación - '+TITLE } },
		{ path: '/products', name: 'products', component: Product, meta: {	requiresAuth: true, title: ".:"+'Productos - '+TITLE } },
		{ path: '/product_types', name: 'product_types', component: ProductType, meta: {	requiresAuth: true, title: ".:"+'Tipos de Producto - '+TITLE } },
    //Datos Generales
		{ path: '/countries', name: 'countries', component: Country, meta: {	requiresAuth: true, title: ".:"+'Paises - '+TITLE } },
		{ path: '/cities', name: 'cities', component: City, meta: {	requiresAuth: true, title: ".:"+'Ciudades - '+TITLE } },
		{ path: '/departments', name: 'departments', component: Department, meta: {	requiresAuth: true, title: ".:"+'Departamento - '+TITLE } },
		{ path: '/sectionals', name: 'sectionals', component: Sectional, meta: {	requiresAuth: true, title: ".:"+'Seccionales - '+TITLE } },
		{ path: '/divisions', name: 'divisions', component: Division, meta: {	requiresAuth: true, title: ".:"+'Divisiones - '+TITLE } },
		{ path: '/faculties', name: 'faculties', component: Faculty, meta: {	requiresAuth: true, title: ".:"+'Facultades - '+TITLE } },
		{ path: '/programs', name: 'programs', component: Program, meta: {	requiresAuth: true, title: ".:"+'Programas - '+TITLE } },
		//Proyectos de Investigación
		{ path: '/announcements', name: 'announcements', component: Announcement, meta: {	requiresAuth: true, title: ".:"+'Convocatorias - '+TITLE } },
		{ path: '/projects', name: 'projects', component: Project, meta: {	requiresAuth: true, title: ".:"+'Proyectos - '+TITLE } },
		{ path: '/my_projects', name: 'my_projects', component: MyProjects, meta: {	requiresAuth: true, title: ".:"+'Mis Proyectos - '+TITLE } },
	];
